import React from 'react'
import { FormattedMessage } from 'react-intl'
import './index.scss'
import microblogLogo from '../../images/footer/microblog-logo.png'
import wechatLogo from '../../images/footer/wechat-logo.png'
import tiktokLogo from '../../images/footer/tiktok-logo.png'

import microblogQRcode from '../../images/footer/microblog-h5.png'
import wechatQRcode from '../../images/footer/wechat-h5.png'
import tiktokQRcode from '../../images/footer/tiktok-h5.png'
export default class Index extends React.Component {
  render() {
    return (
      <footer className="my-footer">
        <div className="setion1 clearfix">
          <div className="footer-phone">
            {this.props.tab[0] === 'jobs' && (
              <p>投递邮箱:luoxiang@goldenridge.ltd</p>
            )}
            <p>
              <span className="footer-phone-title">
                <FormattedMessage id="consultation_southwest" />
              </span>
              <span className="footer-phone-content">
                <span className="mr-20">
                  <FormattedMessage id="consultation_southwest_consultant1" />
                </span>
              </span>
            </p>
          </div>
          <span className="apps-QRcode">
            <div className="wechat cp">
              <img className="qr-code" alt="" src={microblogQRcode} />
              <img alt="" src={wechatLogo} />
            </div>
            <div className="microblog cp">
              <img className="qr-code" alt="" src={wechatQRcode} />
              <img alt="" src={microblogLogo} />
            </div>
            <div className="tik-tok cp">
              <img className="qr-code" alt="" src={tiktokQRcode} />
              <img alt="" src={tiktokLogo} />
            </div>
          </span>
        </div>

        <div className="setion2">
          <span>
            <FormattedMessage id="copyright" />:
            <FormattedMessage id="copyrightDetail" />
          </span>
          <span>|</span>
          <span>
            <a
              target="_blank"
              href="https://beian.miit.gov.cn/#/Integrated/index"
            >
              <FormattedMessage id="ICP" />:<FormattedMessage id="ICPDetail" />
            </a>
          </span>
          {/* <span>|</span>
          公安备案
          <span>
            <a
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51012202000423"
            >
              <FormattedMessage id="ICP" />:<FormattedMessage id="ICPDetail" />
            </a>
          </span> */}
        </div>
      </footer>
    )
  }
}
