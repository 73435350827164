import React, { Component, Fragment } from 'react';
import { myAxiosWithJwt } from '../../util/axios';
import AddJobs from './addjob';
import { Button, Table, Modal, Popconfirm, message } from 'antd';
import {ArrowUpOutlined ,ArrowDownOutlined}  from '@ant-design/icons';
import moment from 'moment';
import './index.scss';
export default class JobsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            tableLoading: true,// table loading
            total: 0,// 总共 多少数据
            page: 1,// 当前第几页
            page_size: 10,
        }
        this.columns = [{
            title: '职位ID',
            dataIndex: 'id',
        }, {
            title: '职位名称',
            dataIndex: 'name',
        }, {
            title: '用人机构',
            dataIndex: 'organization',
        }, {
            title: '职位类别',
            dataIndex: 'category',
        }, {
            title: '职位类型',
            dataIndex: 'job_type',
        }, {
            title: '工作地点',
            dataIndex: 'place',
        }, {
            title: '招聘人数',
            dataIndex: 'recruitment',
            render:(text)=>text===0?'不限人数':text,
        }, {
            title: '发布者',
            dataIndex: 'publisher',
        }, {
            title: '更新时间',
            dataIndex: 'edit_time',
        }, {
            title: '截止时间',
            dataIndex: 'deadline',
            render:(text)=>moment(text).format("YYYY-MM-DD,HH:mm:ss")
        }, {
            title: '发布状态',
            dataIndex: 'publish_status',
        }, {
            title: '操作',
            dataIndex: 'operation',
            render: (text, record,index) => {
                const {dataSource,total,page,page_size} = this.state;
                return <span className='operations'>
                    <a onClick={this.editJobs.bind(this, record)}>编辑</a>
                    <Popconfirm
                        title="确定要删除这条职位吗?"
                        onConfirm={this.deleteItem.bind(this, record)}
                        okText="删除!"
                        cancelText="考虑?"
                    ><a href="#">删除</a></Popconfirm>
                     { !( Math.ceil(total / page_size) === page && index === dataSource.length-1) && <ArrowDownOutlined onClick={this.sortFunc.bind(this,2,record)} />}
                    {!(page===1 && index===0) && <ArrowUpOutlined onClick={this.sortFunc.bind(this,1,record)} />}
                   
                    
                </span>
            }
        },]
    }
    sortFunc(type,record){// 排序功能 type  1：上移,2:下移。 record：当前整条数据
        this.setState({
            tableLoading:true,
        })
        myAxiosWithJwt({
            url:'/admin/jobs/sort',
            data:{
                id:record.id,
                sort_id:record.sort_id,
                direct:type,
            },
            success:(data)=>{
                if(!!data && data.code===0){
                    message.success(`<${record.name}>往${type===1?'上':'下'}移动了一位`);
                    this.getList.call(this);
                }else{
                    message.warn(!!data.msg || '好像出错了哦。')  
                }
            },
            complete:()=>{
                this.setState({
                    tableLoading:false,
                })
            }
        })
      
    }
    componentDidMount() {
        this.getList();
    }
    deleteItem(record = {}, e) {// 删除一个item
        if (!this.state.tableLoading) {
            this.setState({
                tableLoading: true,
            })
            myAxiosWithJwt({
                url: `/admin/jobs/delete/${record.id}`,
                success: (data) => {
                    if (!!data && data.code === 0) {
                        message.success('这就删除了?你到底招到人了没有哦')
                        setTimeout(() => {
                            this.getList();
                        }, 1600)
                    }
                },
                complete: () => {
                    this.setState({
                        tableLoading: false,
                    })
                }
            })
        }
    }
    getList = () => {// 获取职位列表
        this.setState({ tableLoading: true })
        const { page_size, page } = this.state;
        myAxiosWithJwt({
            url: `/admin/jobs/list?page=${page}&=page_size=${page_size}`,
            method: 'get',
            success: (data) => {
                if (!!data && data.code === 0 && !!data.data) {
                    const { items: list } = data.data;

                    this.setState({
                        dataSource: list,
                        total: data.data.total,
                    })
                }
            },
            complete: () => {
                this.setState({
                    tableLoading: false
                })
            }
        })
    }
    addJobs = (e) => {// 打开添加职位modal
        e.preventDefault();
        Modal.confirm({
            title: '新增职位',
            width: 1200,
            className: 'addjob-modal',
            closable: true,
            content: <AddJobs reFreshList={this.getList.bind(this)} addJobType={1} initJobsData={{}} history={this.props.history} />
        })
    }
    editJobs(record, e) {
        e.preventDefault();
        Modal.confirm({
            title: '编辑职位',
            width: 1200,
            className: 'addjob-modal',
            closable: true,
            content: <AddJobs reFreshList={this.getList.bind(this)} addJobType={2} initJobsData={record} history={this.props.history} />
        })
    }
    render() {
        const { dataSource, tableLoading, page_size, total, page } = this.state;
        return <Fragment>
            <div className='jobslist'>
                <header>招聘中心</header>
                <div className='form-box'>
                    <Button type="primary" onClick={this.addJobs}>新增职位</Button>
                </div>
                <Table pagination={{
                    total: total,
                    current: page,
                    pageSize: page_size,
                    onChange: (page) => {
                        this.setState({ page }, () => {
                            this.getList();
                        })
                    }
                }}
                    loading={tableLoading}
                    className='jobslist-table'
                    dataSource={dataSource}
                    rowKey='id' columns={this.columns} />
            </div>
        </Fragment>
    }
}
