import React, { Component } from 'react'
import { FormattedMessage as FM } from 'react-intl' /* react-intl imports */

import './index.scss'

import Img2015 from '../../images/aboutus/milestone/2015.png'
import Img2016 from '../../images/aboutus/milestone/2016.png'
import Img2017_2 from '../../images/aboutus/milestone/2017-2.png'
import Img2017_9 from '../../images/aboutus/milestone/2017-9.png'
import Img2018_5 from '../../images/aboutus/milestone/2018-5.png'
import Img2018_7 from '../../images/aboutus/milestone/2018-7.png'
import Img2019_7 from '../../images/aboutus/milestone/2019-7.png'
import Img2019_9 from '../../images/aboutus/milestone/2019-9.png'
import Img2019_12 from '../../images/aboutus/milestone/2019-12.png'
import Img2020_7 from '../../images/aboutus/milestone/2020-7.png'
import Img2020_12_1 from '../../images/aboutus/milestone/2020-12-1.png'
import Img2020_12_2 from '../../images/aboutus/milestone/2020-12-2.png'

const milestones = [
  { date: '2015', disable: true },
  {
    date: '2015.6',
    disable: false,
    image: Img2015,
    desc: <FM id="mileStoneDesc1" />,
  },
  { date: '2016', disable: true },
  {
    date: '2016.10',
    disable: false,
    image: Img2016,
    desc: <FM id="mileStoneDesc2" />,
  },
  { date: '2017', disable: true },
  {
    date: '2017.2',
    disable: false,
    image: Img2017_2,
    desc: <FM id="mileStoneDesc3" />,
  },
  {
    date: '2017.9',
    disable: false,
    image: Img2017_9,
    desc: <FM id="mileStoneDesc4" />,
  },
  { date: '2018', disable: true },
  {
    date: '2018.5',
    disable: false,
    image: Img2018_5,
    desc: <FM id="mileStoneDesc5" />,
  },
  {
    date: '2018.7',
    disable: false,
    image: Img2018_7,
    desc: <FM id="mileStoneDesc6" />,
  },
  { date: '2019', disable: true },
  {
    date: '2019.7',
    disable: false,
    image: Img2019_7,
    desc: <FM id="mileStoneDesc7" />,
  },
  {
    date: '2019.9',
    disable: false,
    image: Img2019_9,
    desc: <FM id="mileStoneDesc8" />,
  },
  {
    date: '2019.12',
    disable: false,
    image: Img2019_12,
    desc: <FM id="mileStoneDesc9" />,
  },
  { date: '2020', disable: true },
  {
    date: '2020.7',
    disable: false,
    image: Img2020_7,
    desc: <FM id="mileStoneDesc10" />,
  },
  {
    date: '2020.12',
    disable: false,
    image: Img2020_12_1,
    desc: <FM id="mileStoneDesc11" />,
  },
  {
    date: '2020.12',
    disable: false,
    image: Img2020_12_2,
    desc: <FM id="mileStoneDesc12" />,
  },
]

const SCREENWIDTH = document.body.clientWidth
const SCREENHEIGHT = document.body.clientHeight
const screenType = SCREENWIDTH >= 500 // 大屏幕还是小屏幕  大:true.小:false

export default class Milestone extends Component {
  constructor(props) {
    super(props)
    this.state = {
      x_start: 0,
      isMouseDown: false,
    }
    this.timeout = null
  }

  componentDidMount() {
    window.addEventListener('scroll', this.bindHandleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.bindHandleScroll)
  }

  // 防抖
  debounce = (fn, time) => {
    let myTimeout = null
    return () => {
      if (myTimeout !== null) clearTimeout(myTimeout)
      myTimeout = setTimeout(fn, time || 500)
    }
  }

  bindHandleScroll = () => {
    this.debounce(this.bindHandleScroll)
  }

  bindHandleScroll = () => {
    const scrollTop = document.documentElement.scrollTop
    const dom = document.querySelector('.real-milestone-line')

    if (dom) {
      const bannerHeight = document.querySelector('.au-banner')
        ? document.querySelector('.au-banner').offsetHeight
        : 605
      const minTop = 570 + bannerHeight - SCREENHEIGHT
      const maxTop = bannerHeight + 670
      const domLeft = parseInt(dom.style.left || 0)
      const maxLeft = SCREENWIDTH - 3090

      if (this.timeout !== null) clearTimeout(this.timeout)

      if (scrollTop >= minTop && scrollTop <= maxTop) {
        if (domLeft <= (screenType ? maxLeft - 260 : maxLeft)) {
          this.timeout = setTimeout(() => {
            dom.style.left = '0px'
            this.bindHandleScroll()
          }, 2000)
        } else {
          dom.style.left = `${domLeft - 1}px`
          this.timeout = setTimeout(this.bindHandleScroll, 10)
        }
      } else {
        dom.style.left = '0px'
      }
    }
  }

  onMouseDown = (e) => {
    e.preventDefault()

    this.setState({
      isMouseDown: true,
      x_start: e.clientX,
    })
  }

  onMouseMove = (e) => {
    e.preventDefault()

    const { isMouseDown, x_start } = this.state
    const x_move = x_start - e.clientX

    if (isMouseDown && Math.abs(x_move) >= 50) {
      if (this.timeout !== null) clearTimeout(this.timeout)

      const dom = document.querySelector('.real-milestone-line')
      const domLeft = parseInt(dom.style.left || 0)
      const maxLeft = SCREENWIDTH - 3090 - 260
      const newLeft = domLeft - x_move / 30

      if (newLeft < maxLeft) {
        dom.style.left = `${maxLeft}px`
      } else if (newLeft > 0) {
        dom.style.left = '0px'
      } else {
        dom.style.left = `${newLeft}px`
      }
    }
  }

  onMouseUp = (e) => {
    e.preventDefault()

    this.timeout = setTimeout(this.bindHandleScroll, 5000)

    this.setState({
      isMouseDown: false,
    })
  }

  onMouseOut = (e) => {
    e.preventDefault()

    const { isMouseDown } = this.state
    if (isMouseDown) {
      this.timeout = setTimeout(this.bindHandleScroll, 5000)

      this.setState({
        isMouseDown: false,
      })
    }
  }

  onTouchStart = (e) => {
    this.setState({
      isMouseDown: true,
      x_start: e.touches[0].clientX,
    })
  }

  onTouchMove = (e) => {
    const { isMouseDown, x_start } = this.state
    const x_move = x_start - e.touches[0].clientX

    if (isMouseDown && Math.abs(x_move) >= 100) {
      if (this.timeout !== null) clearTimeout(this.timeout)

      const dom = document.querySelector('.real-milestone-line')
      const domLeft = parseInt(dom.style.left || 0)
      const maxLeft = SCREENWIDTH - 3090
      const newLeft = domLeft - x_move / 15

      if (newLeft < maxLeft) {
        dom.style.left = `${maxLeft}px`
      } else if (newLeft > 0) {
        dom.style.left = '0px'
      } else {
        dom.style.left = `${newLeft}px`
      }
    }
  }

  onTouchEnd = () => {
    this.timeout = setTimeout(this.bindHandleScroll, 5000)

    this.setState({
      isMouseDown: false,
    })
  }

  render() {
    return (
      <div className="real-milestone">
        <div className={`real-milestone-container`}>
          <div
            className="real-milestone-line"
            onMouseOut={this.stopPropagation}
          >
            {milestones.map((item, index) => (
              <span
                key={index}
                className={`point ${item.disable ? 'disable' : ''}`}
              >
                {item.disable ? (
                  <strong className="point-title">{item.date}</strong>
                ) : (
                  <div className="real-milestone-imgBox">
                    <img src={item.image} alt="" />
                    <strong className="">{item.date}</strong>
                    <span>{item.desc}</span>
                  </div>
                )}
              </span>
            ))}
          </div>

          <div
            className="real-milestone-cover"
            onMouseDown={this.onMouseDown}
            onMouseMove={this.onMouseMove}
            onMouseUp={this.onMouseUp}
            onMouseOut={this.onMouseOut}
            onTouchStart={this.onTouchStart}
            onTouchMove={this.onTouchMove}
            onTouchEnd={this.onTouchEnd}
          />
        </div>
      </div>
    )
  }
}
