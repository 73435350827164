import React, { Component, Fragment } from 'react'
import { FormattedMessage as FM } from 'react-intl' /* react-intl imports */
import { Link } from 'react-router-dom'
import './index.scss'
import SideBar from '../../components/sidebar'
import Milestone from '../../components/milestone'

import banner from '../../images/aboutus/banner-bg/pic.jpg'

import CE from '../../images/parkrover/CE.png'
import qual from '../../images/parkrover/qualification.png'
import UX from '../../images/parkrover/UX.png'
import advancedEnterprise from '../../images/parkrover/advanced-enterprise.png'
import beingUs from '../../images/aboutus/be-us.png'

import qualCEH5 from '../../images/parkrover/CEH5.png'
import qualImgH5 from '../../images/parkrover/qualificationH5.png'
import qualUXH5 from '../../images/parkrover/UXH5.png'
import highTechH5 from '../../images/parkrover/high-tech-h5.png'

const honorListH5 = [
  {
    img: qualUXH5,
    title: <FM id="aboutusHonorSubtitle2" />,
  },
  {
    img: qualImgH5,
    title: <FM id="aboutusHonorSubtitle3" />,
  },
  {
    img: qualCEH5,
    title: <FM id="aboutusHonorSubtitle4" />,
  },
  {
    img: highTechH5,
    title: <FM id="aboutusHonorSubtitle1" />,
  },
]

const honorList = [
  {
    img: advancedEnterprise,
    title: <FM id="aboutusHonorSubtitle1" />,
  },
  {
    img: UX,
    title: <FM id="aboutusHonorSubtitle2" />,
  },
  {
    img: qual,
    title: <FM id="aboutusHonorSubtitle3" />,
  },
  {
    img: CE,
    title: <FM id="aboutusHonorSubtitle4" />,
  },
]

const sideTabList = [
  { title: <FM id="aboutusSideTitle1" />, key: 'aboutusSideTitle1' },
  { title: <FM id="aboutusSideTitle2" />, key: 'aboutusSideTitle2' },
  { title: <FM id="aboutusSideTitle3" />, key: 'aboutusSideTitle3' },
  { title: <FM id="aboutusSideTitle4" />, key: 'aboutusSideTitle4' },
  { title: <FM id="aboutusSideTitle5" />, key: 'aboutusSideTitle5' },
]

const scrollTopArr = [0, 840, 1710, 2600, 3490]

export default class AboutUs extends Component {
  render() {
    return (
      <Fragment>
        <div className="about-us main-box">
          <SideBar
            scrollArr={[0, 780, 1600, 2500, 3400]}
            scrollTopArr={scrollTopArr}
            sideTabList={sideTabList}
          />
          <section className="au-banner">
            <div className="banner-box">
              <header>
                <FM id="aboutusBannerTitle" />
              </header>
              <img alt="" src={banner} />
              <div className="desc">
                <p>
                  <FM id="aboutusBannerDesc1" />
                </p>
                <p>
                  <FM id="aboutusBannerDesc2" />
                </p>
                <p>
                  <FM id="aboutusBannerDesc3" />
                </p>
              </div>
            </div>
          </section>
          <section className="milestone">
            <header>
              <FM id="mileStoneTitle" />
            </header>
            <Milestone />
          </section>
          <section className="honor">
            <header>
              <FM id="aboutusHonorTilte" />
            </header>
            <div className="honor-box">
              {honorList.map((item, index) => {
                return (
                  <div className="item" key={index}>
                    <img alt="" src={item.img} />
                    <span>{item.title}</span>
                  </div>
                )
              })}
            </div>
          </section>
          <section className="honor-h5">
            <header>
              <FM id="aboutusHonorTilte" />
            </header>
            <div className="honor-box">
              {honorListH5.map((item, index) => {
                return (
                  <div className="item" key={index}>
                    <img alt="" src={item.img} />
                    <span>{item.title}</span>
                  </div>
                )
              })}
            </div>
          </section>
          <section className="to-join-us">
            <header>
              <FM id="aboutusJoinusTitle" />
            </header>
            <span className="subtitle">
              <FM id="aboutusJoinusSubtitle" />
            </span>
            <img alt="" src={beingUs} />
            <Link
              target="_blank"
              className="btn"
              to="/jobs"
              rel="noopener noreferrer"
            >
              <FM id="aboutusJoinusBtn" />
            </Link>
          </section>
          <section className="contact-us">
            <header className="header">
              <FM id="aboutusContactusTitle" />
            </header>
            <div className="contact-us-detail">
              {' '}
              <span>
                <FM id="aboutusContactusItemContacts" />
              </span>
              <span>
                <FM id="aboutusContactusItemPhone" />
              </span>
            </div>
            <div className="items">
              <div className="item">
                <header>
                  <FM id="aboutusContactusItem1Title" />
                </header>
                <span>
                  <FM id="aboutusContactusItem1Address" />
                </span>
              </div>
              <div className="item">
                <header>
                  <FM id="aboutusContactusItem2Title" />
                </header>
                <span>
                  <FM id="aboutusContactusItem2Address" />
                </span>
              </div>
              <div className="item">
                <header>
                  <FM id="aboutusContactusItem3Title" />
                </header>
                <span>
                  <FM id="aboutusContactusItem3Address" />
                </span>
              </div>
            </div>
          </section>
        </div>
      </Fragment>
    )
  }
}
