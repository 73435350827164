import React, { Component, Fragment } from 'react';
import './index.scss'
import { Form, Icon, Spin, Input, InputNumber, Button, Select, DatePicker, Slider, message, Modal } from 'antd';
import moment from 'moment';
import { myAxiosWithJwt } from '../../util/axios.js';
const Option = Select.Option;

const organizationOptions = ['产品体系', '研发体系', '供应链体系', '交付体系', '营销体系', '数据运营体系', '子公司'],// 用人机构 options
    jobTypeOptions = ['全职', '兼职', '实习', '其他'],// 职位类型options
    categoryOptions = ['产品类', '研发类', '生产类', '商务类', '市场类', '运营类', '职能类', '其他类'],// 职位类别 options
    placeOptions = ['中国·成都', '中国·苏州', '中国·北京', '瑞典·哥德堡', '其他地方'],// 工作地点 待选项
    educationOptions = ['博士及以上', '硕士', '本科', '专科', '专科及以下'],// 学历 待选项
    experienceOptions = ['10年以上', '5-10年(含)', '3-5年(含)', '1-3年(含)', '一年以下', '不限经验'];// 工作经验 待选项
class AddJobs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isUploading:false,
            addJobType: 1,// 1 新增职位 2 编辑职位
        }
    }
    componentDidMount() {
        const { addJobType, formData } = this.props;
        if (addJobType === 2) {
            let formDataTemp = {};
            for (let key in formData) {
                formDataTemp[key] = { value: formData[key] };
            }
            this.props.form.setFields(formDataTemp);
        }
    }
    addJobsFunc = (type, e) => { // type:1 发布，2 预览，3 保存。
        this.props.form.validateFields((err, values) => {
            if (!err) {//  如果表单验证通过
                values.deadline = values.deadline.valueOf();// 截止时间 时间戳
                values.maximum_salary = values.salary[1];// 最高薪资
                values.minimum_salary = values.salary[0];// 最低薪资
                if (type === 2) {// 预览
                    const newPapge = this.props.history.createHref({
                        pathname: "/jobs",
                        search: '?newsId=preview',
                    })
                    sessionStorage.setItem("addJobsPreviewData", JSON.stringify(values));
                    window.open(newPapge)
                } else {
                    this.uploadJobs.call(this, type, values);
                }
            } else {
                message.info('必填必选的你看见了没');
            }
        });
    }
    uploadJobs = (type, values) => {// // 上传职位,type:1 发布职位  3 ： 保存职位
        if (!this.state.isUploading) {
            this.setState({isUploading:true})
            myAxiosWithJwt({
                url: this.props.addJobType === 1 ? '/admin/jobs/add' : "/admin/jobs/edit",// this.props.addJobType  1:  添加职位  3: 编辑已有职位
                data: {
                    ...this.props.initJobsData,
                    ...values,
                    publish_status: type === 1 ? '已发布' : '编辑中',// type 用户点击不用的按钮
                },
                success: (data) => {
                    if (!!data && data.code === 0) {
                        const str = type === 1 ? '可以哦!你的职位发布成功了!' : '厉害!这个职位保存好了!'
                        message.success(str);
                        setTimeout(() => {
                            Modal.destroyAll();// 关闭modal
                            this.props.reFreshList();
                        }, 900)
                    }
                },
                complete: () => {
                    setTimeout(() => { this.setState({isUploading:false}) }, 950)
                },
            })
        }
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { addJobType, initJobsData } = this.props;// 1  添加职位  2 编辑已有职位   initJobsData,编辑职位回填的数据
        const { minimum_salary, maximum_salary } = initJobsData || {};
        return <Fragment>
            <Spin spinning={this.state.isUploading}>
                <div className='add-box clearfix'>
                    <Form className="addnews-form clearfix">
                        {addJobType === 2 && <>
                            <Form.Item label='职位ID'>
                                {getFieldDecorator('id', {
                                    initialValue: initJobsData.id,
                                })(<Input disabled={true}
                                    prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                />)}
                            </Form.Item>
                            <Form.Item label='发布状态'>
                                {getFieldDecorator('publish_status', {
                                    initialValue: initJobsData.publish_status,
                                })(<Input disabled={true}
                                    prefix={<Icon type="sync" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                />)}
                            </Form.Item>
                        </>}
                        <Form.Item label='职位名称'>
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: '请输入职位名称' }],
                                initialValue: initJobsData.name,
                            })(<Input
                                prefix={<Icon type="usergroup-delete" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="输入职位名称"
                            />)}
                        </Form.Item>
                        <Form.Item label='用人机构'>
                            {getFieldDecorator('organization', {
                                rules: [{ required: true, message: '请选择用人机构' }],
                                initialValue: initJobsData.organization,
                            })(<Select placeholder="选择用人机构" >
                                {organizationOptions.map((item) => <Option key={item} value={item}>{item}</Option>)}
                            </Select>)}
                        </Form.Item>
                        <Form.Item label='职位类型'>
                            {getFieldDecorator('job_type', {
                                rules: [{ required: true, message: '请选择职位类型' }],
                                initialValue: initJobsData.job_type,
                            })(<Select placeholder="选择职位类型">
                                {jobTypeOptions.map((item) => <Option key={item} value={item}>{item}</Option>)}
                            </Select>)}
                        </Form.Item>
                        <Form.Item label='职位类别'>
                            {getFieldDecorator('category', {
                                rules: [{ required: true, message: '请选择职位类别' }],
                                initialValue: initJobsData.category,
                            })(<Select placeholder="选择职位类别">
                                {categoryOptions.map((item) => <Option key={item} value={item}>{item}</Option>)}
                            </Select>)}
                        </Form.Item>
                        <Form.Item label='工作地点'>
                            {getFieldDecorator('place', {
                                rules: [{ required: true, message: '请选择工作地点' }],
                                initialValue: initJobsData.place,
                            })(<Select placeholder="选择工作地点">
                                {placeOptions.map((item) => <Option key={item} value={item}>{item}</Option>)}
                            </Select>)}
                        </Form.Item>
                        <Form.Item label='截止时间' >
                            {getFieldDecorator('deadline', {
                                rules: [{ required: true, message: '请选择截止时间' }],
                                initialValue: moment(initJobsData.deadline),
                            })(<DatePicker showToday={false}
                                placeholder="选择截止时间" />,
                            )}
                        </Form.Item>
                        <Form.Item label='学历要求'>
                            {getFieldDecorator('education', {
                                rules: [{ required: true, message: '请选择学历要求' }],
                                initialValue: initJobsData.education,
                            })(<Select placeholder="选择学历要求">
                                {educationOptions.map((item) => <Option key={item} value={item}>{item}</Option>)}
                            </Select>)}
                        </Form.Item>
                        <Form.Item label='工作经验'>
                            {getFieldDecorator('experience', {
                                rules: [{ required: true, message: '请选择工作经验' }],
                                initialValue: initJobsData.experience,
                            })(<Select placeholder="选择工作经验">
                                {experienceOptions.map((item) => <Option key={item} value={item}>{item}</Option>)}
                            </Select>)}
                        </Form.Item>
                        <Form.Item label='招聘人数'>
                            {getFieldDecorator('recruitment', {
                                rules: [{ required: true, message: '请输入招聘人数' }],
                                initialValue: initJobsData.recruitment,
                            })(<InputNumber precision={0} min={0} max={1000}
                                prefix={<Icon type="team" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="不限制人数请输入0"
                            />)}
                        </Form.Item>
                        <Form.Item label='薪资范围' className='salary-slider' >
                            {getFieldDecorator('salary', {
                                initialValue: (addJobType === 2 && !!minimum_salary && !!maximum_salary) ? [minimum_salary, maximum_salary] : undefined,
                                rules: [{ required: true, message: '请选择薪资范围' }],
                            })(<Slider range={true} min={1000} max={30000}
                                step={500} tooltipVisible={true}
                                getTooltipPopupContainer={(triggerNode) => triggerNode}
                            />)}
                        </Form.Item>

                        <Form.Item label='工作职责' className='textarea' >
                            {getFieldDecorator('duty', {
                                rules: [{ required: true, message: '请输入工作职责' }],
                                initialValue: initJobsData.duty,
                            })(<Input.TextArea rows={4} placeholder='输入工作职责'
                            />)}
                        </Form.Item>

                        <Form.Item label='任职要求' className='textarea' >
                            {getFieldDecorator('requirement', {
                                rules: [{ required: true, message: '请输入任职要求' }],
                                initialValue: initJobsData.requirement,
                            })(<Input.TextArea rows={4} placeholder='输入任职要求'
                            />)}
                        </Form.Item>

                    </Form>
                    <Button className='btn' type="primary" onClick={this.addJobsFunc.bind(this, 1)}>发布</Button>
                    <Button className='btn' type="primary" onClick={this.addJobsFunc.bind(this, 2)}>预览</Button>
                    <Button className='btn' type="primary" onClick={this.addJobsFunc.bind(this, 3)}>保存</Button>
                    <Button className='btn' onClick={()=>{Modal.destroyAll();}}>取消</Button>
                </div>
            </Spin>
        </Fragment>
    }
}
const WrappedAddNews = Form.create({ name: 'Addjobs' })(AddJobs);

export default WrappedAddNews;