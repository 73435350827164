import React, { Component, Fragment } from "react";
import { FormattedMessage as FM, FormattedMessage } from "react-intl"; /* react-intl imports */

import playVideo from "../../components/vediomodal";
import Performance from "../../components/performance";
import Scene from "../../components/scene";
import Sites from "../../components/sites";
import "./index.scss";
import banner from "../../images/parkrover/banner.png";
import bannerH5 from "../../images/parkrover/bannerH5.png";
import qualCE from "../../images/parkrover/CE.png";
import qualImg from "../../images/parkrover/qualification.png";
import qualUX from "../../images/parkrover/UX.png";

import qualCEH5 from "../../images/parkrover/CEH5.png";
import qualImgH5 from "../../images/parkrover/qualificationH5.png";
import qualUXH5 from "../../images/parkrover/UXH5.png";

const qualList = [
  {
    image: qualUX,
    key: <FM id="qualSubTitle1" />,
  },
  {
    image: qualImg,
    key: <FM id="qualSubTitle2" />,
  },
  {
    image: qualCE,
    key: <FM id="qualSubTitle3" />,
  },
];
const qualListH5 = [
  {
    image: qualUXH5,
    key: <FM id="qualSubTitle1" />,
  },
  {
    image: qualImgH5,
    key: <FM id="qualSubTitle2" />,
  },
  {
    image: qualCEH5,
    key: <FM id="qualSubTitle3" />,
  },
];
function getScrollWidth() {
  var noScroll,
    scroll,
    oDiv = document.createElement("DIV");
  oDiv.style.cssText =
    "position:absolute; top:-1000px; width:100px; height:100px; overflow:hidden;";
  noScroll = document.body.appendChild(oDiv).clientWidth;
  oDiv.style.overflowY = "scroll";
  scroll = oDiv.clientWidth;
  document.body.removeChild(oDiv);
  return noScroll - scroll;
}
const screenWidth =
  (document.documentElement.clientWidth || document.body.clientWidth) -
  getScrollWidth();
const screenHeight =
  document.documentElement.clientHeight || document.body.clientHeight;
const tempHeight = 60 + (screenWidth / 1400) * 960 + 900 - screenHeight - 1100; // 到滑动到这个高度时，应该让价格框滑动
export default class ParkRover extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this.throttle.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.throttle.bind(this));
  }
  throttle() {
    // 节流方法
    //没有上次处理时间，或者超过上次处理时间400ms
    if (!this.lastDealTime || Date.now() - this.lastDealTime >= 10) {
      this.lastDealTime = Date.now(); // 记录当次处理时间
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop >= tempHeight) {
        if (!!this.priceBox)
          this.priceBox.style.top = `${500 - scrollTop + tempHeight}px`;
      }
    }
  }
  courseSceneFunc(type) {
    const { sceneTab } = this.state;
    if (sceneTab === 0 && type === "right") {
      this.setState({
        sceneFormerTab: sceneTab,
        sceneTab: 3,
      });
    } else if (sceneTab === 3 && type === "left") {
      this.setState({
        sceneFormerTab: sceneTab,
        sceneTab: 0,
      });
    } else {
      this.setState({
        sceneFormerTab: sceneTab,
        sceneTab: type === "left" ? sceneTab + 1 : sceneTab - 1,
      });
    }
  }
  render() {
    return (
      <Fragment>
        <div className="main-box">
          <div className="swiper">
            {/* <MyReactSwiper items={bannerList} /> */}
            <img className="web" alt="" src={banner} />
            <img className="h5" alt="" src={bannerH5} />
            <div className="cover">
              <span className="header">
                <FM id="parkroverSectionHeaderTitle" />
              </span>
              <span className="text">
                <FM id="parkroverSectionHeaderText" />
              </span>
              <span
                className="to-watch-video white cp"
                onClick={() => {
                  playVideo(
                    "https://goldenridge-app.oss-cn-hangzhou.aliyuncs.com/goldenridge_parkrover_ad.mp4"
                  );
                }}
              >
                <FM id="towatchVideo" />
                <div className="triangle"></div>
              </span>
              <div className="tips">
                <FormattedMessage id="tips"></FormattedMessage>
              </div>
            </div>
          </div>
          <div className="product-detail">
            <div className="sub-title">
              <FM id="productDetailText1" />
            </div>
            <div className="sub-title">
              <FM id="productDetailText2" />
            </div>
            {/* 
                    <div className='price-details' ref={child => this.priceBox = child}>
                        <header className='price-details-header'><FM id='priceField' /><span><FM id='priceDetail' /></span></header>
                        <div className='up-box'>
                            <div className='items'>
                                <span className='bordered up-box-header-box'><span><FM id='pDHeaderLeft' /></span><span><FM id='pDHeaderLeftPrice' /></span></span>
                                <div className='item-left'>
                                    <span><FM id='pDItem1Title1' /></span>
                                    <span><FM id='pDItem1Price1' /></span>
                                </div>
                                <div className='item-left'>
                                    <span><FM id='pDItem1Title2' /></span>
                                    <span><FM id='pDItem1Price2' /></span>
                                </div>
                                <div className='item-left'>
                                    <span><FM id='pDItem1Title3' /></span>
                                    <span><FM id='pDItem1Price3' /></span>
                                </div>
                                <div className='item-left'>
                                    <span><FM id='pDItem1Title4' /></span>
                                    <span><FM id='pDItem1Price4' /></span>
                                </div>
                                <div className='item-left'>
                                    <span><FM id='pDItem1Title5' /></span>
                                    <span><FM id='pDItem1Price5' /></span>
                                </div>
                            </div>
                            <div className='items'>
                                <span className='up-box-header-box'><span><FM id='pDHeaderRight' /></span><span><FM id='pDHeaderRightPrice' /></span></span>
                                <div className='item-right'>
                                    <span><FM id='pDItem1Title6' /></span>
                                    <span><FM id='pDItem1Price6' /></span>
                                </div>
                                <div className='item-right'>
                                    <span><FM id='pDItem1Title7' /></span>
                                    <span><FM id='pDItem1Price7' /></span>
                                </div>
                                <div className='item-right'>
                                    <span><FM id='pDItem1Title8' /></span>
                                    <span><FM id='pDItem1Price8' /></span>
                                </div>
                            </div>
                        </div>

                        <div className='down-box'>
                            <div className='down-box-header'>
                                <span className='down-box-header-title'><FM id='pDHeaderDown' /></span>
                                <span className='down-box-header-sub-title'><FM id='pDHeaderDownSub' /></span>
                            </div>
                            <div className='down-box-items'>
                                <div className='down-box-item'>
                                    <div className='down-box-item-title'>—&nbsp;&nbsp;<FM id='pdItemdownLeftTitle' />&nbsp;&nbsp;—</div>
                                    <div className='down-box-item-price'><FM id='pdItemdownLeftprice' /></div>
                                    <div className='down-box-item-sub-title'><FM id='pdItemdownLeftSubTitle' /></div>
                                </div>
                                <div className='down-box-item'>
                                    <div className='down-box-item-title'>—&nbsp;&nbsp;<FM id='pdItemdownRightTitle' />&nbsp;&nbsp;—</div>
                                    <div className='down-box-item-price'><FM id='pdItemdownRightprice' /></div>
                                    <div className='down-box-item-sub-title'><FM id='pdItemdownRightSubTitle' /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                 */}
          </div>
          <Performance />
          <div className="qualification">
            <div className="qualification-box">
              <header className="header">
                <span>
                  <FM id="qualTitle" />
                </span>
                <span>
                  <FM id="qualText" />
                </span>
              </header>

              <div className="items">
                {qualList.map((item, index) => (
                  <div className="item" key={index}>
                    <img alt="" src={item.image} />
                    <div>{item.key}</div>
                  </div>
                ))}
              </div>
              <div className="items-h5">
                {qualListH5.map((item, index) => (
                  <div className="item" key={index}>
                    <img alt="" src={item.image} />
                    <div>{item.key}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Scene />
          <Sites />
        </div>
      </Fragment>
    );
  }
}
