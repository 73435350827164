import React, { Component, Fragment } from 'react';
import { FormattedMessage as FM } from 'react-intl'; /* react-intl imports */

import './index.scss';
import pointIcon from '../../images/parkrover/point-icon.png'

import siteImg1 from '../../images/parkrover/siteimgs/01.png'
import siteImg2 from '../../images/parkrover/siteimgs/02.png'
import siteImg3 from '../../images/parkrover/siteimgs/03.jpg'
import siteImg4 from '../../images/parkrover/siteimgs/04.jpg'
import siteImg5 from '../../images/parkrover/siteimgs/05.jpg'
import siteImg6 from '../../images/parkrover/siteimgs/06.jpg'
import siteImg7 from '../../images/parkrover/siteimgs/07.jpg'
import siteImg8 from '../../images/parkrover/siteimgs/08.jpg'
import siteImg9 from '../../images/parkrover/siteimgs/09.jpg'
import siteImg10 from '../../images/parkrover/siteimgs/10.jpg'
import siteImg11 from '../../images/parkrover/siteimgs/11.jpg'
import siteImg12 from '../../images/parkrover/siteimgs/12.jpg'
import siteImg13 from '../../images/parkrover/siteimgs/13.jpg'
import siteImg14 from '../../images/parkrover/siteimgs/14.jpg'
import siteImg15 from '../../images/parkrover/siteimgs/15.jpg'
const siteImgs = [{
    image: siteImg1,
    desc: <FM id='event1' />,
    id: '01'
}, {
    image: siteImg2,
    desc: <FM id='event2' />,
    id: '02'
}, {
    image: siteImg3,
    desc: <FM id='event3' />,
    id: '03'
}, {
    image: siteImg4,
    desc: <FM id='event4' />,
    id: '04'
}, {
    image: siteImg5,
    desc: <FM id='event5' />,
    id: '05'
}, {
    image: siteImg6,
    desc: <FM id='event6' />,
    id: '06'
}, {
    image: siteImg7,
    desc: <FM id='event7' />,
    id: '07'
}, {
    image: siteImg8,
    desc: <FM id='event8' />,
    id: '08'
}, {
    image: siteImg9,
    desc: <FM id='event9' />,
    id: '09'
}, {
    image: siteImg10,
    desc: <FM id='event10' />,
    id: '10'
}, {
    image: siteImg11,
    desc: <FM id='event11' />,
    id: '11'
}, {
    image: siteImg12,
    desc: <FM id='event12' />,
    id: '12'
}, {
    image: siteImg13,
    desc: <FM id='event13' />,
    id: '13'
}, {
    image: siteImg14,
    desc: <FM id='event14' />,
    id: '14'
}, {
    image: siteImg15,
    desc: <FM id='event15' />,
    id: '15'
}
]
const SCREENWIDTH = document.body.clientWidth;
const screenType = SCREENWIDTH>=500;// 大屏幕还是小屏幕  大:true.小:false
const citys = ['cityChengdu', 'cityBeijing', 'cityGuangzhou', 'cityVienna'];
const leftArr = screenType?[0,-3900,-6500,-8450]:[0,-410,-820,-1148];
const itemWidth = screenType?650:82;// 每个item的宽度，包括marginLeft

export default class Sites extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nowCity: 0,// 现在哪个城市 对应 citys 数组
            leftNum:0,// 决定定位  左移的值
        }
    }
    componentDidMount() {
        this.listenerNode = document.getElementById('sites-scroll-cover');
        this.listenerNode.addEventListener('touchstart', this.touchStart);
        this.listenerNode.addEventListener('touchend', this.touchEnd);
    }
    touchStart = (e)=>{
        e.preventDefault();
        this.startX = e.touches[0].pageX;
    }
    touchEnd=(e)=>{
        e.preventDefault();
        this.moveEndX = e.changedTouches[0].pageX;
        this.X = this.moveEndX - this.startX;
            if (this.X < -50) {
                this.clickJump.call(this, 'left')
            }
            else if (this.X > 50) {
                this.clickJump.call(this, 'right')
            }
    }
    componentWillUnmount(){
        this.listenerNode.removeEventListener('touchstart', this.touchStart)
        this.listenerNode.removeEventListener('touchend', this.touchEnd)
    }
    pointJump = (index) => {
        const { nowCity } = this.state;
        if (index !== nowCity) this.setState({ 
            nowCity: index,// 当前城市
            leftNum:leftArr[index],
         })
    }
    
    clickJump=(type)=>{ // 用户点击箭头的移动  find
        const {leftNum,nowCity} = this.state;
        if((type==='right' && leftNum === leftArr[0]) || (type==='left' && leftNum === leftArr[3])){
            return;
        }
        const nextLeftNum = type==='left'? leftNum - itemWidth :leftNum + itemWidth ;// 下一次的left距离
        const cityTemp = leftArr.find((item)=>item === (type==='left'?nextLeftNum:nextLeftNum-itemWidth)); //找到是否移到了下一个城市，
        this.setState({ 
            leftNum: nextLeftNum,
            nowCity:cityTemp!==undefined? (type==='left'?leftArr.indexOf(cityTemp):leftArr.indexOf(cityTemp)-1):nowCity,
         })
    }
    render() {
        const { nowCity,leftNum } = this.state;
        return <div className='sites' ref={child => this.scrollBox = child}>
            <div className='line'>
                {citys.map((item, index) => <Fragment key={index} >
                    <span className={`${nowCity === index ? 'active point cp' : 'point cp'}`} onClick={this.pointJump.bind(this, index)}></span>
                    <div className='city-name'><FM id={item} /></div>
                </Fragment>)}
                <img className='icon' alt="" src={pointIcon} style={{ left: `calc(${(nowCity + 1) * (100/(leftArr.length+1))}% - ${screenType?24:18}px)` }} />
            </div>
            <span className='left-arrow  cp' onClick={this.clickJump.bind(this,'right')}></span>
            <span className='right-arrow cp' onClick={this.clickJump.bind(this,'left')}></span>
            <div className='cover' id='sites-scroll-cover'></div>
            <div  className='scroll-box clearfix' style={{left:`${leftNum}${screenType?'px':'vw'}`}}>
                {siteImgs.map((item,index) => <div key={index}  className='site-item'>
                    <img alt='' src={item.image} />
                    <span>{item.desc}</span>
                </div>)}
            </div>

        </div>
    }
}