import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import MyHeader from './components/myheader';
import MyFooter from './components/myfooter';
import IndexPage from './pages/IndexPage';
import ParkRover from './pages/parkrover';
import Application from './pages/application';
import Jobs from './pages/jobs';// c端  工作列表
import AboutUs from './pages/aboutus';
import News from "./pages/news";
import NewsDetail from './pages/newsdetail'
import Login from './pages/login'
import AdminIndex from './pages/adminindex'


import './index.scss';
//关于多语言配置的一些引入
import { IntlProvider  } from 'react-intl'; /* react-intl imports */
import CN from './language/CN.js'     // import defined messages in Chinese
import EN from './language/EN.js'    // import defined messages in English

const messages = {// 多语言配置待用列表
  en: EN,
  cn: CN
};
class MainApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lanType: 'cn',
      tab: ['index'],
    }
  }
  setLanType = (lanType) => {  
    this.setState({
      lanType
    },()=>{
      localStorage.setItem('lan',lanType);// 保存用户语言
    })
  }
  setTab = (tab, callback) => {
    this.setState({
      tab
    }, () => {
      if (!!callback && typeof (callback) === 'function') callback(); // 如果有回调方法
      else { // 要不然每次切换tab都回到页顶
        window.scrollTo({
          top: 0,
          behavior: "instant"
        })
      }
    })
  }
  componentDidMount(){
      const lanType = localStorage.getItem('lan');//重现用户语言习惯
      if(!!lanType && lanType === 'en'){
        this.setState({
          lanType
        })
      }
  }
  render() {
    const { lanType, tab, } = this.state;
    const { setLanType, setTab, } = this;

    return <IntlProvider locale={lanType} messages={messages[lanType]}>
      <BrowserRouter>
        <MyHeader setType={setLanType} lanType={lanType} setTab={setTab} tab={tab} />
        <Route path='/parkrover' component={ParkRover} />
        <Route path='/application' component={Application} />
        <Route exact path='/'  >
          <IndexPage setTab={setTab} />
        </Route>
        <Route path='/news' component={News} />
        <Route path='/newsdetail' component={NewsDetail} />
        <Route path='/jobs' component={Jobs} />
        <Route path='/aboutus' component={AboutUs} />

        {/*下面都是管理页面*/}
        <Route path='/login' component={Login} />
        <Route path='/adminindex' component={AdminIndex} />


        <MyFooter tab={tab} />
      </BrowserRouter>

    </IntlProvider>
  }
}

ReactDOM.render(
  <MainApp />,
  document.getElementById('root')
);

serviceWorker.unregister();
