

import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import { withRouter } from "react-router-dom";
import blackLogo from "../../images/header/logo_black.svg";
import whiteLogo from "../../images/header/logo_white.svg";
import closeLogo from '../../images/close.png'

import './index.scss';
const headerList = [
    { title: <FormattedMessage id='parkrover' />, key: 'parkrover', },
    { title: <FormattedMessage id='industryApply' />, key: 'application', },
    { title: <FormattedMessage id='news' />, key: 'news', },
    { title: <FormattedMessage id='aboutus' />, key: 'aboutus', },
];
const h5HeaderList = [
    { title: <FormattedMessage id='index' />, key: '', },
    { title: <FormattedMessage id='parkrover' />, key: 'parkrover', },
    { title: <FormattedMessage id='industryApply' />, key: 'application', },
    { title: <FormattedMessage id='news' />, key: 'news', },
    { title: <FormattedMessage id='aboutus' />, key: 'aboutus', },
];
class MyHeader extends React.Component {
    componentDidMount() {
        let tempStr = this.props.history.location.pathname.substr(1);
        tempStr = tempStr === '' ? 'index' : tempStr;
        this.props.setTab([tempStr])
        this.props.history.listen(route => {// 监听路由变化
            let tempStr = route.pathname.substr(1);
            tempStr = tempStr === '' ? 'index' : tempStr;
            this.props.setTab([tempStr])
            this.setState({
                isShowh5Tab: false
            })
        })
    }
    constructor(props) {
        super(props);
        this.state = {
            isShowh5Tab: false
        }
    }
    showh5Tab() {
        this.setState({
            isShowh5Tab: !this.state.isShowh5Tab
        })
    }
    render() {
        const { tab, lanType, setType } = this.props;
        const svgFill = (this.state.isShowh5Tab || tab[0] === 'index') ? "#e6e6e6" : '#2c2c2c'
        return <header className={`top-header${tab[0] === 'index' ? ' bg-black' : ' bg-white'}`}>
            <div className='header-logo'>
                <Link to={'/'}>
                    <img alt='' src={(this.state.isShowh5Tab || tab[0] === 'index') ? whiteLogo : blackLogo} className='cp' />
                </Link>
            </div>
            <div className='header-list'>
                <Menu mode='horizontal' defaultSelectedKeys={['parkrover']} selectedKeys={tab} >
                    {headerList.map((item, index) => {
                        return <Menu.Item key={item.key}><Link to={'/' + item.key}>{item.title}</Link></Menu.Item>
                    })}
                </Menu>
            </div>
            <div className={`lan-switch${tab[0] === 'index' ? ' white' : ' black'}`} ><span className='cp' onClick={() => { setType(lanType === 'cn' ? 'en' : 'cn') }}>{lanType === 'cn' ? 'EN' : 'CN'}</span></div>
            <div className='h5-header-btn cp' onClick={this.showh5Tab.bind(this)}>
                {!this.state.isShowh5Tab ?
                    <svg t="1597735963213" viewBox="0 0 1024 1024" width="32" height="32">
                        <path fill={svgFill} d="M896 307.2l-768 0c-14.1312 0-25.6-11.4688-25.6-25.6s11.4688-25.6 25.6-25.6l768 0c14.1312 0 25.6 11.4688 25.6 25.6s-11.4688 25.6-25.6 25.6z" p-id="1154" ></path>
                        <path fill={svgFill} d="M896 563.2l-768 0c-14.1312 0-25.6-11.4688-25.6-25.6s11.4688-25.6 25.6-25.6l768 0c14.1312 0 25.6 11.4688 25.6 25.6s-11.4688 25.6-25.6 25.6z" p-id="1155" ></path>
                        <path fill={svgFill} d="M896 819.2l-768 0c-14.1312 0-25.6-11.4688-25.6-25.6s11.4688-25.6 25.6-25.6l768 0c14.1312 0 25.6 11.4688 25.6 25.6s-11.4688 25.6-25.6 25.6z" p-id="1156" ></path></svg>
                    : <img alt='' src={closeLogo} />}
            </div>

            <div className={`h5-tab ${this.state.isShowh5Tab ? 'has-height' : ''}`}>
                {h5HeaderList.map((item, index) => {
                    return <Link key={item.key} to={'/' + item.key}>{item.title}</Link>
                })}
                <div className={`lan-switch-h5${tab[0] === 'index' ? ' white' : ' black'}`} ><span className='cp' onClick={() => { setType(lanType === 'cn' ? 'en' : 'cn') }}>{lanType === 'cn' ? 'EN' : 'CN'}</span></div>
            </div>
        </header>
    }
}
export default withRouter(MyHeader)