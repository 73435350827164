import React, { Component,Fragment } from 'react';
import { FormattedMessage as FM } from 'react-intl'; /* react-intl imports */
import './index.scss'
import playVideo from '../../components/vediomodal';
import SideBar from '../../components/sidebar'

import car1 from '../../images/application/car1.png'
import car2 from '../../images/application/car2.png'
import car3 from '../../images/application/car3.png'


const sideTabList = [
    { title: <FM id='applictionSideTitle1' />, key: 'applictionSideTitle1', },
    { title: <FM id='applictionSideTitle2' />, key: 'applictionSideTitle2', styleType:'black' },
    { title: <FM id='applictionSideTitle3' />, key: 'applictionSideTitle3', },
];

export default class Application extends Component {
    render() {
        return  <Fragment>
         <div className='main-box application'>
               <SideBar scrollArr={[0, 780, 1580]} scrollTopArr={[0, 900, 1800]} sideTabList={sideTabList} />
            <div className='item item1'>
                <header><FM id='applictionTitle1' /></header>
                <span className='to-watch-video white cp' onClick={()=>{
                    playVideo('https://goldenridge-app.oss-cn-hangzhou.aliyuncs.com/goldenridge_check_ad.mp4')
                }}>
                    <FM id='towatchVideo' />
                    <div className='triangle'></div>
                </span>
                <img alt='' src={car1} />
            </div>
            <div className='item item2'>
                <header><FM id='applictionTitle2' /></header>
                <span className='to-watch-video black cp' onClick={()=>{
                    playVideo('https://goldenridge-app.oss-cn-hangzhou.aliyuncs.com/goldenridge_scooter2_ad.mp4')
                }} >
                    <FM id='towatchVideo' />
                    <div className='triangle'></div>
                </span>
                <img alt='' src={car3} />
            </div>
            <div className='item item3'>
                <header><FM id='applictionTitle3' /></header>
                <span className='to-watch-video white cp' onClick={()=>{
                    playVideo('https://goldenridge-app.oss-cn-hangzhou.aliyuncs.com/goldenridge_scooter1_ad.mp4')
                }}>
                    <FM id='towatchVideo' />
                    <div className='triangle'></div>
                </span>
                <img alt='' src={car2} />
            </div>
        </div>
        </Fragment>
    }
}