//   本地  indexed Db 库
// Define your database

function myDb() {
    let db = new window.Dexie("news_database");
    // 创建数据库
    db.version(1).stores({
        news: 'name,value',// news  有个 value 字段？
    });
    return {
        pushData: (values, success = () => { }, fail = () => { }) => {
            db.news.put({name:'news',value: JSON.stringify(values) })
                .then(function (res) {
                    success();
                    db.close();
                })
                .catch(function (error) {
                    fail();
                    console.log("Ooops: " + error);
                    db.close();
                });
        },
        getData: () => {
            return new Promise((resolve,) => {
                db.news.get('news').then(function (res) {
                    resolve(res);
                })
            })
        },
        close: () => {
            db.close();
        },
       
    }
}

export default myDb


