/**
 * 
 * JK   20200519
 *  关于当前项目登录和权限的工具库
 *  
 */
import axios from 'axios';
import { myAxios } from './axios.js';
import Cookies from 'js-cookie';
import { message } from 'antd';
message.config({
    top: 300,
    maxCount: 3,
});

let loginStatus = 'none';
axios.interceptors.request.use((config) => {
    // 在发送请求之前做些什么
    if (config.url.includes('admin/users/login')) {// 如果是登录
        loginStatus = 'loading'
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 登陆方法
function login(data, success) {
    if (loginStatus !== 'loading') { // 如果没有正在登陆请求
        myAxios({
            url: '/admin/users/login',
            data,
            success: (data) => {
                if (!!data && data.code === 0) {
                    Cookies.remove('jwt');
                    Cookies.set('jwt', data.data.jwt);
                    if (typeof (success) === 'function') {// 执行回调
                        success();
                    }
                } else {
                    message.info({
                        content: data.msg,
                        duration: 1.5,
                        top: 200,
                    });
                }
            },
            fail: (err) => {
                console.log(err);
                if (!!err)
                    message.info({
                        content: err,
                        duration: 1.5,
                        top: 200,
                    });
            },
            complete: () => {
                loginStatus = 'finish';
            }
        })
    }

}

// 检查登陆状态
function getToken() {
    return `Bearer ${Cookies.get('jwt')}`;
}
function refresh() {

}
export { login, refresh, getToken }



