import React, { Component } from 'react';
import { FormattedMessage as FM } from 'react-intl'; /* react-intl imports */
import { Menu } from 'antd';
import './index.scss'
import chassisHeightImg from '../../images/parkrover/chassis-height.png';
import sizeImg from '../../images/parkrover/size.png';
import temperatureImg from '../../images/parkrover/temperature.png';
import trafficabilityImg from '../../images/parkrover/trafficability.png';

const performanceList = [{
    image: sizeImg,
    title: <FM id='carSize' />,
    key: 'size',
    index: 0,
}, {
    image: chassisHeightImg,
    title: <FM id='chassisHeight' />,
    key: 'chassisHeight',
    index: 1,
}, {
    image: trafficabilityImg,
    title: <FM id='trafficability' />,
    key: 'trafficability',
    index: 2,
}, {
    image: temperatureImg,
    title: <FM id='workingTemperature' />,
    key: 'temperature',
    index: 3,
},]

export default class Performance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            performanceTab: 0,// 性能图片切换  tab
            performancePreTab: -1,// 前一个tab
            type: 'left',// 下次动画的方向  left  right  
        }
        this.isMoving = false;// 标记没有正在动画
        this.setDate = (state) => {
            if (!this.isMoving)// 如果动画执行完成
                this.setState(state, () => {// 每次设置超过以后都要执行动画  需要一秒  所以一秒以内 不能再 设置动画
                    this.isMoving = true;
                    if (!this.isMovingInterval) {
                        this.isMovingInterval = setTimeout(() => {
                            this.isMoving = false;
                            this.isMovingInterval = null;
                        }, 800)
                    }
                })
        }
    }
    courseFunc(type) {
        const { performanceTab } = this.state;
        if (performanceTab === 0 && type === 'right') {
            this.setDate({
                performancePreTab: performanceTab,
                performanceTab: performanceList.length-1,
                type,
            })
        } else if (performanceTab === performanceList.length-1 && type === 'left') {
            this.setDate({
                performancePreTab: performanceTab,
                performanceTab: 0,
                type,
            })
        } else {
            this.setDate({
                performancePreTab: performanceTab,
                type,
                performanceTab: type === 'left' ? performanceTab + 1 : performanceTab - 1,
            })
        }
    }
    onSelect = ({ item, key, keyPath, domEvent }) => {
        for (let item of performanceList) {//遍历 找到对应的图片
            if (item.key === key) {
                const { performanceTab } = this.state;
                if (item.index !== performanceTab) // 如果点击的tab对应当前的图片  就不用切换
                    this.setDate({
                        performancePreTab: performanceTab,
                        performanceTab: item.index,
                        type: item.index > performanceTab ? 'left' : 'right',
                    })
            }
        }
    }
    render() {
        const { performanceTab, performancePreTab, type } = this.state;
        return <div className='performance'>
            <Menu mode='horizontal' onClick={this.onSelect}
                defaultSelectedKeys={[performanceList[performanceTab].key]} selectedKeys={[performanceList[performanceTab].key]} >{performanceList.map((item, index) => {
                    return <Menu.Item key={item.key}>{item.title}</Menu.Item>
                })}</Menu>
            <div className='performance-imgs'>
                <span className='left-arrow  cp' onClick={this.courseFunc.bind(this, 'right')}></span>
                <span className='right-arrow cp' onClick={this.courseFunc.bind(this, 'left')}></span>
                {performanceList.map((item, index) => {
                    return <img
                        className={`${index === performancePreTab ? `imgs-${type}-hide` :
                            (index === performanceTab ? `imgs-${type}-show` : 'imgs-item')}`}
                        alt={item.key} key={item.key} src={item.image} />
                })}
            </div>
        </div>
    }
}