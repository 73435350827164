const CN = {
    cn: "cn",
    // header

    index: '主页',
    parkrover: "智能漫游车",
    industryApply: '行业应用',
    news: '新闻中心',
    aboutus: '关于我们',

    //footer
    contact: '联系方式',// 联系方式 字段
    contacts: '王先生',// 联系人 实体
    email: '邮箱',// 邮箱字段
    copyright: '版权所有',
    copyrightDetail: '四川金瑞麒智能科学技术有限公司',
    ICP: '备案号',
    ICPDetail: '蜀ICP备18023667号',
    consultation_southwest: '咨询热线：',
    consultation_southwest_consultant1: '135-4796-7349 罗先生',
    consultation_southwest_consultant2: '180-4030-5301 王先生',
    consultation_east: '咨询热线：',
    consultation_east_consultant1: '137-0185-3861 方先生',
    consultation_east_consultant2: '186-5598-4958 刘先生',


    //indexpage
    boxGoldridgeTitle: '自动驾驶智能漫游车',// parkrover页 首屏 标题
    boxGoldridgeSubTitle: '前100台尊享版限时预售中',// 同上 副标题
    boxAutodriveTitle: '全球低速自动驾驶技术的引领者',// Autodrive 首屏 标题
    boxAutodriveSubTitle1: '全面保障驾乘安全',
    boxAutodriveSubTitle: '高精定位导航 ｜ 精准识别障碍 ｜ 360°实时监控感知 ｜ 主动安全决策机制 ｜ 电子围栏设定 ',// 同上 副标题
    boxIntellChassisTitle: '自主研发的精准底盘线控技术',
    boxIntellChassisSubTitle: `"功能车身与底盘"分离设计，模块化封装 ｜ 独立悬架系统，原地转向控制灵活`,
    boxIndustryApply: '行业应用',
    boxIndustryApplicationTitle1: '智能漫游车',
    boxIndustryApplicationTitle2: '智能查验车',
    boxIndustryApplicationTitle3: '智能代步车二代',
    boxIndustryApplicationTitle4: '智能代步车一代',
    buyNow: '立即抢购',
    //indexpage sideBAr
    sideGoldenridge: 'GOLDENRIDGE',
    sideAutoDrive: '自动驾驶',
    sideIntellChassis: '智能底盘',
    sideIndustryApply: '行业应用',
    // parkrover section 1
    parkroverSectionHeaderTitle: '自动驾驶智能漫游车',
    parkroverSectionHeaderText: '尊享版限时发售中',
    // parkrover section 2
    productDetailText1: '大自在智能漫游车，是四川金瑞麒智能科学技术有限公司自主设计研发，于2019年百度AI开发者大会发布推出，是载人低速自动驾驶领域目前真正实现量产和场景化应用的产品，可广泛应用于工业/科技园区、旅游景区、城市公园、度假村等各场景。 ',
    productDetailText2: '智能漫游车依托于金瑞麒自有的基于室内外融合的低速自动驾驶技术和机器人底盘控制技术，是一款集代步、科技、资讯和娱乐互动为一体的新娱乐载体的智能出行产品。',
    // parkrover section 3
    carSize: '车体尺寸',
    chassisHeight: '底盘高度',
    trafficability: '通过性',
    workingTemperature: '工作温度',

    // parkrover section 4
    priceField: '售价：',// 售价字段
    priceDetail: '¥300,000/台',// 售价数字
    pDHeaderLeft: '部署费用 ',
    pDHeaderLeftPrice: '¥60,000',
    pDHeaderRight: '年服务费用 ',
    pDHeaderRightPrice: '¥50,000',
    pDItem1Title1: 'Cros基站',
    pDItem1Title2: '充电柜',
    pDItem1Title3: '勘测设计施工基站',
    pDItem1Title4: '景区导览信息录入',
    pDItem1Title5: '无人车运输费',
    pDItem1Title6: '自动驾驶技术服务费',
    pDItem1Title7: '无人车专项保险',
    pDItem1Title8: '车辆质保延长服务',
    pDItem1Price1: '¥30,000/台',
    pDItem1Price2: '¥5,000/台',
    pDItem1Price3: '¥15,000/次',
    pDItem1Price4: '¥10,000/次',
    pDItem1Price5: '¥12/公里',
    pDItem1Price6: '¥30,000/台.年',
    pDItem1Price7: '¥5,000/台.年 ',
    pDItem1Price8: '¥15,000/台.年',

    pDHeaderDown: '套餐优惠',
    pDHeaderDownSub: '限时特惠',
    pdItemdownLeftTitle: '基础套餐',
    pdItemdownLeftprice: '¥50,000',
    pdItemdownLeftSubTitle: '包含全部部署费用',
    pdItemdownRightTitle: '超值套餐',
    pdItemdownRightprice: '¥90,000',
    pdItemdownRightSubTitle: '包含全部部署费用和年服务费用',

    // qualification
    qualTitle: '匠心研制，注定出生不凡！',
    qualText: '已通过中国汽车工程研究院股份有限公司检测中心的整车资质认证和国际CE认证，获得德国UX交互设计奖。',
    qualSubTitle1: '德国UX交互设计奖',
    qualSubTitle2: '整车资质',
    qualSubTitle3: 'CE认证',
    // scene 
    sceneText: '多元化应用场景',

    //sites citys
    cityChengdu: '成都',
    cityBeijing: '北京',
    cityGuangzhou: '广州',
    cityVienna: '维也纳',
    // sites events
    event1: '成都 兴隆湖',
    event2: '成都 兴隆湖',
    event3: '成都 北湖公园',
    event4: '成都 北湖公园',
    event5: '成都 江滩公园',
    event6: '成都 江滩公园',
    event7: '北京 百度科技园',
    event8: '北京 2018百度AI开发者大会',
    event9: '北京 2019百度AI开发者大会1',
    event10: '北京 2019百度AI开发者大会',
    event11: '广州 2019智能网联汽车驾驶大赛',
    event12: '广州 2019智能网联汽车驾驶大赛',
    event13: '广州 2019智能网联汽车驾驶大赛',
    event14: '维也纳 2019维也纳世界未来交通大会',
    event15: '维也纳 2019维也纳世界未来交通大会',

    //about us 
    aboutusBannerTitle: "公司介绍",
    aboutusBannerDesc1: '金瑞麒源自瑞典哥德堡的 Golden Ridge Robotic 和 Techno Creatives 两家公司，有长期为沃尔沃、奥迪、奔驰及萨博等著名车企提供人机交互体验和概念车设计的丰富经验，曾获得十多项红点（RED DOT）奖、IF奖等国际工业设计最高奖项。',
    aboutusBannerDesc2: '金瑞麒汇集中国和欧美一流企业及院校  的人才，拥有国际领先的基于室内外融合的低速自动驾驶技术和机器人底盘控制技术，是全球低速自动驾驶技术解决方案及服务的引领者。',
    aboutusBannerDesc3: '金瑞麒与百度达成深度战略合作，核心成果广泛应用于文旅、康复、物流等众多行业领域，致力于为用户提供优质的智能出行服务。',
    // about us  milestone
    mileStoneTitle: '企业里程碑 ',

    mileStoneDesc1: 'Golden Ridge Robotic AB 瑞典哥德堡成立',
    mileStoneDesc2: '产品原理样机在深圳双创周展出',
    mileStoneDesc3: '金瑞麒在中国成都和苏州落户',
    mileStoneDesc4: '第一代智能代步车 北京国际康辅具博览会展出',
    mileStoneDesc5: '第二代智能代步车 苏州人工智能博览会展出',
    mileStoneDesc6: '金瑞麒智能代步车 百度AI开发者大会、百度世界大会 及 2019 CES 发布',
    mileStoneDesc7: '智能漫游车 2019年百度AI开发者大会上发布',
    mileStoneDesc8: '智能漫游车 大熊猫研究保护中心、成都兴隆湖 及成都北湖公园试运营开始',
    mileStoneDesc9: '智能漫游车 将在世界未来交通大会亮相 奥地利·维也纳',
    mileStoneDesc10: '第二十三届成都国际汽车展览会无人驾驶试乘独家合作方',
    mileStoneDesc11: '2020年百度Apollo生态大会',
    mileStoneDesc12: '2020 Gitex科技周',

    // about us honor
    aboutusHonorTilte: '企业资质及荣誉',
    aboutusHonorSubtitle1: '国家级高新技术企业证书',
    aboutusHonorSubtitle2: '德国UX交互设计奖',
    aboutusHonorSubtitle3: '整车资质',
    aboutusHonorSubtitle4: 'CE认证',

    //about us join us
    aboutusJoinusTitle: '加入我们',
    aboutusJoinusSubtitle: "阳光少年，风华正茂；不忘初心，砥砺前行。",
    aboutusJoinusBtn: '立即查看职位',

    //about us contact us
    aboutusContactusTitle: '联系我们',

    aboutusContactusItemContacts: '邮箱：service@goldenridge.ltd',
    aboutusContactusItemPhone: '网站：www.goldenridge.cn',
    aboutusContactusItem1Title: '四川金瑞麒智能科学技术有限公司',
    aboutusContactusItem1Address: '地 址：中国（四川）自由贸易区成都市天府新区成都科学城天府菁蓉中心 D 区南区 B2 栋 3 层 ',

    aboutusContactusItem2Title: '苏州金瑞麒智能科技有限公司 ',
    aboutusContactusItem2Address: '地 址：江苏省苏州市苏州工业园区金芳路 18 号东 坊创智园地 A 栋 6 楼 ',

    aboutusContactusItem3Title: ' Golden Ridge Robotic AB',
    aboutusContactusItem3Address: '地 址：Smörgatan 16, Gothenburg, Sweden ',

    aboutusSideTitle1: '公司介绍',
    aboutusSideTitle2: '企业里程碑',
    aboutusSideTitle3: '资质与荣誉',
    aboutusSideTitle4: '加入我们',
    aboutusSideTitle5: '联系我们 ',

    // appliction 
    applictionTitle1: "智能查验车",
    applictionTitle2: "智能代步车2",
    applictionTitle3: "智能代步车1",
    towatchVideo: '观看视频',

    applictionSideTitle1: '智能查验车',
    applictionSideTitle2: '智能代步车2',
    applictionSideTitle3: '智能代步车1',

    //tips
    tips: '温馨提示：本视频含个性化定制开发内容，实际产品以约定交付条款为准',
}
export default CN;