import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom'
import { myAxios } from "../../util/axios.js";
import { Spin, Pagination } from 'antd'
import './index.scss'

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page_size: 7,// 第一次7条  以后6条
            page: 1,// 默认第一页
            total: 0,
            isLoading: false,
            list: [],
        }
    }
    componentDidMount() {
        this.getList.call(this);
    }
    getList() {
        const { page_size, page, isLoading } = this.state;
        if (!isLoading) {
            this.setState({
                isLoading: true,
            })
            myAxios({
                url: `/web/news/list?page=${page}&page_size=${page_size}`,
                method: 'get',
                success: (data) => {
                    if (!!data && data.code === 0 && data.data.items.length) {
                        this.setState({
                            total: data.data.total,
                            list: page === 1 ? data.data.items : [this.state.list[0], ...data.data.items],
                        })
                    }else{

                    }
                },
                complete: () => {
                    this.setState({
                        isLoading: false,
                    })
                }
            })
        }
    }
    render() {
        const { page_size, page, isLoading, list, total } = this.state;
        return <Fragment>
            <div className='main-box news-box'>
                <header className='header'>官方新闻</header>
                {isLoading ? <Spin className='spin-box' /> :!!list.length? <><div className='items clearfix'>
                    {list.map((item, index) => {
                        return <div key={index} className={`item cp ${index % 3 === 0 ? 'margin-right' : ''}`} >
                            <Link target='_blank' to={{
                                pathname: '/newsdetail',
                                search: '?newsId=' + item.id,
                            }}>
                                <img alt='' src={item.img} />
                                <div className='date'>{item.edit_time}<span>金瑞麒</span></div>
                                <div className='title'>{item.main_title}</div>
                                <div className='detail'>{item.comment}</div>
                            </Link>
                        </div>
                    })}
                </div> 
                <div className='pagination-box clearfix'>
                    <Pagination current={page} pageSize={page_size} total={total-1}
                        onChange={(page, ) => {
                                this.setState({// 如果不是第一页  就只拿6条数据
                                    page_size: page===1?7:6,
                                    page
                                }, () => {
                                    this.getList();
                                })
                        }} /></div>
                </>:<div className='none'>感谢您的关注，暂时没有最新消息</div>}
               

            </div>
        </Fragment>
    }
}
export default withRouter(News)