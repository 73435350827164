import React, { Component, Fragment } from 'react';
import { getQueryVariable } from '../../util/util'
import './index.scss'
import { myAxios } from '../../util/axios.js';
import { message, Spin } from 'antd';
import moment from 'moment'
import myDb from '../../util/indexeddb.js';
export default class NewsDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            newsDetail: {},
            isLoading: true,
        }
    }
    componentDidMount() {
        const id = getQueryVariable('newsId');
        if (id === 'preview') {// 预览模式
            console.log(window)
            myDb().getData().then((res) => {

               const item =  JSON.parse(res.value)
               item.publish_time = 'XXXX-XX-XX'
                this.setState({
                    newsDetail: item,
                    isLoading:false,
                })
            })
        } else {
            this.getDetail.call(this, id)
        }
    }
    getDetail = (id) => {
        myAxios({
            url: '/web/news?id=' + id,
            data: {
                id: id,
            },
            method: 'get',
            success: (data) => {
                console.log(data)
                if (!!data && data.code === 0) {
                    this.setState({
                        newsDetail: data.data,
                    })
                } else {
                    message.info(data.msg);
                }
            },
            complete: () => {
                this.setState({
                    isLoading: false,
                })
            }
        })
    }
    render() {
        const { newsDetail, isLoading } = this.state;
        return <Fragment>
            <div className='news-detail-container'>
                {isLoading ? <Spin /> : <div className='box'>
                    <div className='date'>{newsDetail.publish_time.substring(0, 10)}<span>金瑞麒</span></div>
                    <div className='header'>{newsDetail.main_title}</div>
                    {/* <img className='main-img' alt='' src={newsDetail.img}  /> */}
                    <div dangerouslySetInnerHTML={{ __html: newsDetail.content }}></div>
                </div>}
            </div>
        </Fragment>
    }
}