
/*
    管理员 主页 
*/

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import { Menu } from 'antd';
import './index.scss'
import NewsList from '../newscenter/newslist'
import JobsList from '../jobscofig/jobslist';// 管理端 工作列表
const versionNumber = '2.3.2';
const host = window.location.host;
let strTemp = '';
if (host.includes('localhost')) {
    strTemp = 'localhost';
} else if (host.includes('dev')) {
    strTemp = 'dev';
} else if (host.includes('fat')) {
    strTemp = 'fat';
}else {
    strTemp = 'pro';
}
class Index extends Component {
    render() {
        return <div className='admin-box'>
            <Router>
                <Menu onClick={this.handleClick}
                    className='admin-nav'
                    defaultSelectedKeys={['sub1']}
                    defaultOpenKeys={['sub1']}
                    mode="inline">
                    <Menu.Item key="sub1" title='新闻中心'><Link to="/adminindex/">新闻中心</Link></Menu.Item>
                    <Menu.Item key="sub2" title='招聘中心'><Link to="/adminindex/jobsconfig">招聘中心</Link></Menu.Item>
                </Menu>
                <div className='admin-body'>
                    <Route exact path="/adminindex/" component={NewsList} />
                    <Route path="/adminindex/jobsconfig" component={JobsList} />
                </div>
                <div className='version'>{strTemp + versionNumber}</div>
            </Router>
        </div>
    }
}
export default Index

