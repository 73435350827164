import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl'; /* react-intl imports */
import { withRouter } from 'react-router-dom'
import SideBar from '../../components/sidebar';
import './index.scss';
import goldridgeImg from '../../images/indexpage/goldridge.png'
import autoDriveImg from '../../images/indexpage/autodrive.gif'
import intellChassis from '../../images/indexpage/intell-chassis.png'

import applyImg1 from '../../images/indexpage/apply-img1.png'
import applyImg2 from '../../images/indexpage/apply-img2.png'
import applyImg3 from '../../images/indexpage/apply-img3.png'
import applyImg4 from '../../images/indexpage/apply-img4.png'
const sideTabList = [
  { title: <FormattedMessage id='sideGoldenridge' />, key: 'sideGoldenridge', },
  { title: <FormattedMessage id='sideAutoDrive' />, key: 'sideAutoDrive', },
  { title: <FormattedMessage id='sideIntellChassis' />, key: 'sideIntellChassis', },
  { title: <FormattedMessage id='sideIndustryApply' />, key: 'sideIndustryApply', },
];
let defaultTop = 10; // 刚好展示完第一页的 top 值
// 行业应用 产品列表
const industryApplyList = [{
  img: applyImg1,key:'智能漫游车', text: <FormattedMessage id='boxIndustryApplicationTitle1' />
}, {
  img: applyImg2,key:'智能查验车', text: <FormattedMessage id='boxIndustryApplicationTitle2' />
}, {
  img: applyImg3,key:'智能代步车二代', text: <FormattedMessage id='boxIndustryApplicationTitle3' />
}, {
  img: applyImg4,key:'智能代步车一代', text: <FormattedMessage id='boxIndustryApplicationTitle4' />
}]
class IndexPage extends Component {
  // 点击跳转页面时调用
  scrollJumpFunc = (page) => {
    window.scrollTo({
      top: defaultTop + (page - 1) * 900,
      behavior: "smooth"
    })
  }
  render() {
    return <Fragment>
      <div className='main-box'>
        <div className='box goldridge'>
          <header className='title'><FormattedMessage id='boxGoldridgeTitle' /></header>
          <div className='sub-title'>—&nbsp;&nbsp;<FormattedMessage id='boxGoldridgeSubTitle' />&nbsp;&nbsp;—</div>
          <img alt='漫游车logo' src={goldridgeImg} />
          <a href='http://j9xmx6rmjy8rwvzo.mikecrm.com/TKXVl7X' target='_blank'><div className='toparkrover cp'><FormattedMessage id='buyNow' /></div></a>
          <div className='pull-scrolldown cp' onClick={this.scrollJumpFunc.bind(this, 2)}><div></div></div>
        </div>
        <div className='box autodrive'>
          <header className='title'><FormattedMessage id='boxAutodriveTitle' /></header>
          <div className='sub-title'>—&nbsp;&nbsp;<FormattedMessage id='boxAutodriveSubTitle1' />&nbsp;&nbsp;—</div>
          <div className='sub-title1'><FormattedMessage id='boxAutodriveSubTitle' /></div>
          <img className='gif' alt='自动驾驶动画' src={autoDriveImg} />
          <div className='pull-scrolldown cp' onClick={this.scrollJumpFunc.bind(this, 3)}>
            <div></div>
          </div>
        </div>
        <div className='box intelligent-chassis'>
          <header className='title'><FormattedMessage id='boxIntellChassisTitle' /></header>
          <div className='sub-title'><FormattedMessage id='boxIntellChassisSubTitle' /></div>
          <img alt='智能底盘' src={intellChassis} />
          <div className='pull-scrolldown cp' onClick={this.scrollJumpFunc.bind(this, 4)}><div></div></div>
        </div>
        <div className='box industry-apply'>
          <header className='title'><FormattedMessage id='boxIndustryApply' /></header>
          <div className='h5-scroll-box'>
            <div className='list clearfix'>
              {industryApplyList.map((item, index) => <div key={item.key} className='item cp'>
                <img alt={item.key} src={item.img} />
                <span onClick={() => {
                  this.props.history.push({
                    pathname: index === 0 ? "parkrover" : '/application'
                  })
                  this.props.setTab(index === 0 ? ['parkrover'] : ['application'], () => {
                    window.scrollTo({
                      top: (index - 1) * 900 >= 0 ? (index - 1) * 900 : 0,
                      behavior: "instant"
                    })
                  })
                }}>{item.text}</span>
                <div className='cover'></div>
              </div>)}
            </div>
          </div>
        </div>
        <SideBar scrollArr={[0, 780, 1680, 2480]} scrollTopArr={[0, 900, 1800, 2700]} sideTabList={sideTabList} />
      </div>
    </Fragment>
  }
}


export default withRouter(IndexPage);
