import React, { Component, Fragment } from 'react';
import './index.scss'
import { Form, Icon, Input, Button } from 'antd';
import {login} from '../../util/authority.js';
class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            password: ''
        }
    }
    componentDidMount() {
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // console.log('Received values of form: ', values);
               login({
                ...values
            },()=>{
                this.props.history.push('/adminindex')
            })
            }
        });
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        return <Fragment>
            <div className='login-box'>
                <header className='login-header'>金瑞麒官网后台管理系统</header>
                <div className='real-box'>
                    <Form onSubmit={this.handleSubmit} className="login-form">
                        <Form.Item>
                            {getFieldDecorator('username', {
                                rules: [{ required: true, message: '输入账号啊!' }],
                                initialValue : '',
                            })(
                                <Input className='username' 
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="Username"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: '请输入密码!' }],
                                initialValue : '',
                            })(
                                <Input  className='psd'
                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="password"
                                    placeholder="Password"
                                />,
                            )}
                        </Form.Item>
                        <Button className='btn' type="primary" htmlType="submit">登录</Button>
                    </Form>
                   
                </div>
            </div>
        </Fragment>
    }
}
const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(Login);

export default WrappedNormalLoginForm;