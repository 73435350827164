//sideTab



import React, { Component } from 'react';
import './index.scss'

const defaultTop = 10; // 刚好展示完第一页的 top 值
export default class SideBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.throttle.bind(this));
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.throttle.bind(this));
        this.setState = (state, callback) => {
            return;
        };
    }
    throttle = () => {// 节流方法
        const { scrollArr } = this.props;
        //没有上次处理时间，或者超过上次处理时间400ms
        if (!this.lastDealTime || (Date.now() - this.lastDealTime >= 30)) {
            this.lastDealTime = Date.now();// 记录档次处理时间
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            for (let index = 0; index < scrollArr.length; index++) {
                if (scrollTop >= scrollArr[index] && (index === scrollArr.length - 1 || scrollTop < scrollArr[index + 1])) {
                    this.setState({ page: index + 1 })
                }
            }
        }
    }

    render() {
        const { scrollTopArr, sideTabList, } = this.props;
        const { page, } = this.state;
        return <div className={`sideTab ${sideTabList[page - 1].styleType}`} style={{ height: `${scrollTopArr.length * 70}px` }}>
            {sideTabList.map((item, index) => <div
                className={`side-bar-item cp ${(page === index + 1) ? 'active' : ''}`}
                onClick={() => {
                    if (page !== index + 1)
                        this.setState({ page: index + 1 }, () => {
                            window.scrollTo({
                                top: defaultTop + scrollTopArr[this.state.page - 1],
                                behavior: "smooth"
                            })
                        })
                }}
                key={item.key}>
                <span>{item.title}</span>
            </div>)}
        </div>
    }
}