import React, { Component, Fragment } from 'react';
import { Button, Table, Modal, message, Popconfirm, } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import moment from 'moment';
import AddNews from './addnews';
import { myAxiosWithJwt } from '../../util/axios';
import './index.scss'

class NewsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            tableLoading: true,// table loading
            total: 0,// 总共 多少数据
            page: 1,// 当前第几页
            page_size: 10,
        }
        this.columns = [{
            title: '新闻名称',
            dataIndex: 'main_title',
        }, {
            title: '新闻渠道',
            dataIndex: 'news_source',
        }, {
            title: '发布者',
            dataIndex: 'publisher',
        }, {
            title: '编辑时间',
            dataIndex: 'edit_time',
        }, {
            title: '有效期',
            dataIndex: 'vaild_date',
            render: (text) => moment(new Date(text)).format('YYYY-MM-DD'),
        }, {
            title: '发布状态',
            dataIndex: 'publish_status',
        }, {
            title: '操作',
            dataIndex: 'operation',
            render: (text, record, index) => {
                const { dataSource, total, page, page_size } = this.state;
                return <span className='operations'>
                    <a onClick={this.editNews.bind(this, record)}>编辑</a>
                    <Popconfirm
                        title="确定要删除这条新闻吗?"
                        onConfirm={this.deleteItem.bind(this, record)}
                        okText="删除!"
                        cancelText="再想想"
                    ><a href="#">删除</a></Popconfirm>

                    {!(Math.ceil(total / page_size) === page && index === dataSource.length - 1) && <ArrowDownOutlined onClick={this.sortFunc.bind(this, 2, record)} />}
                    {!(page === 1 && index === 0) && <ArrowUpOutlined onClick={this.sortFunc.bind(this, 1, record)} />}

                </span>
            }
        },];
    }
    sortFunc(type, record) {// 排序功能 type  1：上移,2:下移。 record：当前整条数据
        this.setState({
            tableLoading: true,
        })
        myAxiosWithJwt({
            url: '/admin/news/sort',
            data: {
                id: record.id,
                sort_id: record.sort_id,
                direct: type,
            },
            success: (data) => {
                if (!!data && data.code === 0) {
                    message.success(`<${record.main_title}>往${type === 1 ? '上' : '下'}移动了一位`);
                    this.getList.call(this);
                } else {
                    message.warn(!!data.msg || '好像出错了哦。')
                }
            },
            complete: () => {
                this.setState({
                    tableLoading: false,
                })
            }
        })

    }
    componentDidMount() {
        this.getList();
    }
    getList = () => {// 获取新闻列表
        this.setState({ tableLoading: true })
        const { page_size, page } = this.state;
        myAxiosWithJwt({
            url: `/admin/news/list?page=${page}&page_size=${page_size}`,
            method: 'get',
            success: (data) => {
                if (!!data && data.code === 0 && !!data.data) {
                    const { items: list } = data.data;
                    this.setState({
                        dataSource: list,
                        total: data.data.total,
                    })
                }
            },
            complete: () => {
                this.setState({
                    tableLoading: false
                })
            }
        })
    }
    addnews = () => {// 打开添加新闻modal
        Modal.confirm({
            title: '新增新闻',
            width: 1200,
            className: 'addnew-modal',
            closable: true,
            content: <AddNews reFreshList={this.getList.bind(this)} addnewsType={1} initNewsData={{}} history={this.props.history} />
        })
    }
    editNews(record, e) {// 编辑新闻
        e.preventDefault();
        const { tableLoading } = this.state;
        if (!tableLoading) {
            this.setState({ tableLoading: true })
            myAxiosWithJwt({
                url: '/admin/news?id=' + record.id,
                method: 'get',
                success: (data) => {
                    if (!!data && data.code === 0) {
                        Modal.confirm({
                            title: '编辑新闻',
                            width: 1200,
                            className: 'addnew-modal',
                            closable: true,
                            content: <AddNews reFreshList={this.getList.bind(this)} initNewsData={data.data} addnewsType={2} history={this.props.history} />
                        })
                    } else {
                        message.warn(data.msg || '获取数据失败!')
                    }
                },
                complete: () => {
                    this.setState({ tableLoading: false })
                }
            })
        }


    }
    deleteItem(record = {}, e) {// 删除一个item
        if (!this.state.tableLoading) {
            this.setState({
                tableLoading: true,
            })
            myAxiosWithJwt({
                url: `/admin/news/delete/${record.id}`,
                success: (data) => {
                    if (!!data && data.code === 0) {
                        message.success('好，这个新闻已经变成了旧闻!')
                        setTimeout(() => {
                            this.getList();
                        }, 1600)

                    }
                },
                complete: () => {
                    this.setState({
                        tableLoading: false,
                    })
                }
            })
        }
    }
    render() {
        const { dataSource, tableLoading, total, page, page_size } = this.state;
        return <Fragment>
            <div className='newslist'>
                <header>新闻列表</header>
                <div className='form-box'>
                    <Button type="primary" onClick={this.addnews}>新增新闻</Button>
                </div>
                <Table pagination={{
                    total: total,
                    current: page,
                    pageSize: page_size,
                    onChange: (page) => {
                        this.setState({ page }, () => {
                            this.getList();
                        })
                    }
                }}
                    rowKey='id'
                    loading={tableLoading}
                    className='newslist-table'
                    dataSource={dataSource}
                    columns={this.columns} />
            </div>

        </Fragment>
    }
}
export default NewsList;