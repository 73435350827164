import React, { Component, Fragment } from 'react';
import './index.scss'
import { Form, Icon, Spin, Input, Button, Upload, DatePicker, message, Modal } from 'antd';
import OSS from "ali-oss";
import moment from 'moment';
import { myAxiosWithJwt } from '../../util/axios.js';
import myDb from '../../util/indexeddb.js';

const Editor = require('wangeditor');
class AddNews extends Component {
    constructor(props) {
        super(props)
        this.state = {
            coverImg: '',
            ossLoading: false,// 是否正在上传图片
            isUploading: false,
        }
    }
    componentDidMount() {
        // 创建编辑器
        this.editor = new Editor('#editor');
        this.editor.customConfig.uploadImgShowBase64 = true;// 可传本地图片,
        this.editor.create()
        const { initNewsData } = this.props;
        if (JSON.stringify(initNewsData) !== '{}') {
            this.editor.txt.html(initNewsData.content)
            this.setState({
                coverImg: initNewsData.img
            })
        }
    }
    addNewsFunc = (type, e) => { // type:1 发布，2 预览，3 保存。
        this.props.form.validateFields((err, values) => {
            if (!err) {//  如果表单验证通过
                const text = this.editor.txt.html();
                if (text === '<p><br></p>') { // 如果  正文内容为空
                    message.info('你他妈正文倒是写点东西啊！');
                } else {
                    if (!this.state.coverImg) {
                        message.warn('请选择封面图！');
                        return;
                    }
                    values.img = this.state.coverImg;// 封面图base64，
                    values.content = text;// 新闻正文，
                    values.vaild_date = values.vaild_date.valueOf();// 字符化时间
                    values.edit_time = undefined;
                    if (type === 2) {// 预览
                        const newPapge = this.props.history.createHref({
                            pathname: "/newsdetail",
                            search: '?newsId=preview',
                        })
       
                        let db = myDb();// 获取db方法对象
                        let dbWaiting = true;// 正在等待db保存
                        message.loading({
                            content: '正在保持预览内容,请等待...',
                            duration: 0,// 不自动关闭
                        })
                        let timer = setTimeout(() => {
                            if (dbWaiting) {// 10s后还没有保存成功
                                dbWaiting = false;
                                message.destroy();
                                message.error({ content: '预览内容保存失败,请重试!',key:'updatable',duration: 2 });
                                timer = null;
                            }
                        }, 20000)
                        db.pushData(values, () => {
                            if(timer) clearTimeout(timer);
                            message.destroy();
                          
                            window.open(newPapge);// 打开新页面
                        });

                        // sessionStorage.setItem("addNewsPreviewData", JSON.stringify(values));
                        // window.open(newPapge)
                    } else {
                        this.uploadNews.call(this, type, values);
                    }
                }
            } else {
                message.info('必填！必填的你看不见吗？');
            }
        });
    }
    uploadNews = (type, values) => {// 上传新闻 type:1 发布新闻  3 ： 保存新闻
        if (!this.state.isUploading) {
            this.setState({ isUploading: true })
            myAxiosWithJwt({
                url: this.props.addnewsType === 1 ? '/admin/news/add' : "/admin/news/edit",// this.props.addnewsType  1:  添加新闻  3: 编辑已有新闻
                data: {
                    ...this.props.initNewsData,
                    ...values,
                    publish_status: type === 1 ? '已发布' : '编辑中',// type 用户点击不用的按钮
                },
                success: (data) => {
                    if (!!data && data.code === 0) {
                        const str = type === 1 ? '你的新闻发布成功了!' : '你的新闻保存成功了!'
                        message.success(str);
                        setTimeout(() => {
                            Modal.destroyAll();// 关闭modal
                            this.props.reFreshList();
                        }, 900)
                    }
                },
                complete: () => {
                    setTimeout(() => { this.setState({ isUploading: false }) }, 950)
                },
            })
        }
    }
    beforeUpload = async (file, fileList) => {
        await this.getOSSToken.call(this);
        const { access_key, secret_key, token } = this.ossData;
        const ossClient = new OSS({ // 创建对象
            region: "oss-cn-hangzhou",
            secure: true,
            bucket: "tourist-source",
            accessKeyId: access_key,
            accessKeySecret: secret_key,
            stsToken: token,
        });
        await ossClient
            .put(file.name, file)
            .then(async (res) => {
                const { name, url } = res || {};
                if (!!url && !!name) {
                    await ossClient.putACL(name, "public-read");// 放成公共资源 以便其他人能访问
                    this.setState({
                        coverImg: url,// 图片获取base64地址
                        ossLoading: false,// 上传完成了
                    })
                }

            })
            .catch((err) => {
                console.error(err);
                message.error("上传失败，请重试");
            });
        return false;
    }
    getOSSToken() {
        if (!this.state.ossLoading) {
            this.setState({ ossLoading: true })
            return new Promise((resolve, reject) => {
                myAxiosWithJwt({
                    method: 'get',
                    url: '/admin/users/getOssToken',
                    success: (data) => {
                        if (!!data) {
                            this.ossData = data.data;
                            resolve();
                        } else {
                            reject();
                        }
                    },
                    fail: () => {
                        this.setState({ ossLoading: false })
                    }
                })
            })
        } else return Promise.reject();
    }

    render() {
        const { getFieldDecorator, } = this.props.form;
        const { initNewsData, addnewsType } = this.props;// 待回填的数据
        const { coverImg, ossLoading } = this.state;
        return <Fragment>
            <Spin spinning={this.state.isUploading}>
                <div className='add-box clearfix'>
                    <Form className="addnews-form clearfix">
                        {addnewsType === 2 && <>
                            <Form.Item label='新闻ID'>
                                {getFieldDecorator('id', {
                                    initialValue: initNewsData.id,
                                })(<Input disabled={true}
                                    prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                />)}
                            </Form.Item>
                            <Form.Item label='发布状态'>
                                {getFieldDecorator('publish_status', {
                                    initialValue: initNewsData.publish_status,
                                })(<Input disabled={true}
                                    prefix={<Icon type="sync" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                />)}
                            </Form.Item>
                        </>}

                        <Form.Item label='新闻名称'>
                            {getFieldDecorator('main_title', {
                                rules: [{ required: true, message: '请输入新闻名称' }],
                                initialValue: initNewsData.main_title,
                            })(<Input
                                prefix={<Icon type="edit" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="输入新闻名称"
                            />)}
                        </Form.Item>

                        <Form.Item label='有效期' >
                            {getFieldDecorator('vaild_date', {
                                rules: [{ required: true, message: '请选择有效期' }],
                                initialValue: moment(initNewsData.vaild_date),
                            })(
                                <DatePicker className='add-news-datepicker'
                                    showToday={false}
                                    prefix={<Icon type="form" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="选择有效期" />,
                            )}
                        </Form.Item>

                        <Form.Item label='新闻渠道'>
                            {getFieldDecorator('news_source', {
                                rules: [{ required: true, message: '请输入新闻渠道' }],
                                initialValue: initNewsData.news_source,
                            })(<Input
                                prefix={<Icon type="api" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="输入新闻渠道"
                            />)}
                        </Form.Item>

                        <Form.Item label='引用地址'>
                            {getFieldDecorator('source_link', {
                                initialValue: initNewsData.source_link,
                            })(<Input
                                prefix={<Icon type="link" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="输入引用地址"
                            />)}
                        </Form.Item>

                        <Form.Item label='新闻摘要' className='width100'>
                            {getFieldDecorator('comment', {
                                rules: [{ required: true, message: '请输入新闻摘要' }],
                                initialValue: initNewsData.comment,
                            })(
                                <Input
                                    prefix={<Icon type="form" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="输入新闻摘要"
                                />,
                            )}
                        </Form.Item>

                        <Form.Item label='封面配图' className='width100 cover-img-spin-wrapper'>
                            {getFieldDecorator('img', {
                                valuePropName: 'fileList',
                            })(
                                <Upload accept="image/*" beforeUpload={(file, fileList) => {
                                    this.beforeUpload(file, fileList);
                                    return false;
                                }} showUploadList={false}>
                                    <Button>
                                        <Icon type="upload" /> 点击选择封面配图
                            </Button>
                                </Upload>,
                            )}
                            {!!coverImg && <div className='cover-mig-box'><img alt='' src={coverImg} /></div>}
                            {ossLoading && < Spin />}
                        </Form.Item>

                    </Form>

                    <label className='editor-label'>正文内容：</label>
                    <div><div id='editor' /></div>

                    <Button className='btn' type="primary" onClick={this.addNewsFunc.bind(this, 1)}>发布</Button>
                    <Button className='btn' type="primary" onClick={this.addNewsFunc.bind(this, 2)}>预览</Button>
                    <Button className='btn' type="primary" onClick={this.addNewsFunc.bind(this, 3)}>保存</Button>
                    <Button className='btn' onClick={() => {
                        Modal.destroyAll();
                    }}>取消</Button>
                </div>
            </Spin>
        </Fragment>
    }
}
const WrappedAddNews = Form.create({ name: 'addnews' })(AddNews);

export default WrappedAddNews;