import React, { Component, Fragment } from 'react';
import { Spin, Pagination, message } from 'antd';
import moment from 'moment';
import { getQueryVariable } from '../../util/util.js';
import { myAxios } from '../../util/axios.js';
import './index.scss'

import jobsBanner from '../../images/aboutus/jobs-banner.png'
export default class Jobs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            list: [],
            total: 0,
            page: 1,
            page_size: 10,
        }
    }
    componentDidMount() {
        const id = getQueryVariable('newsId');
        if (id === 'preview') {// 预览模式
            let itemTemp = JSON.parse(sessionStorage.getItem("addJobsPreviewData"));
            itemTemp = { ...itemTemp, id: 1 };
            this.setState({
                list: [itemTemp],
                isLoading: false,
            })
        } else {
            this.getList.call(this);
        }
    }
    getList = () => {
        const { page_size, page } = this.state;
        myAxios({
            url: `/web/jobs/list?page=${page}&page_size=${page_size}`,
            method: 'get',
            data: {
                page,
                page_size,
            },
            success: (data) => {
                this.setState({ isLoading: false })
                if (!!data && data.code === 0) {
                    this.setState({
                        total: data.data.total,
                        list: data.data.items,
                    })
                } else {
                    message.warn(!!data.msg ? data.msg : '页面错误!');
                }
            },
            fail: (data) => {
                message.warn(!!data ? data.msg : '页面错误!');
            },
            complete: () => {
                this.setState({ isLoading: false })
            }
        })
    }
    render() {
        const { isLoading, list, page_size, total, page } = this.state;
        return <Fragment>
            <div className='jobs'>
                <img className='banner' alt='' src={jobsBanner} />
                {isLoading ? <Spin className='spin-box' /> :
                   !!list.length? <>
                        <div className='items'>
                            {list.map((item, index) => <div key={item.id} className='item'>
                                <header className='header'>
                                    <span>{item.name}</span>
                                    <span >{moment(item.deadline).format('YYYY-MM-DD')}</span>
                                </header>
                                <div className='details'>
                                    <span>岗位职责：</span>
                                    <span>{item.duty}</span>
                                </div>
                                <div className='details'>
                                    <span>任职要求：</span>
                                    <span>{item.requirement}</span>
                                </div>
                            </div>)}
                        </div>
                        <Pagination current={page} pageSize={page_size} total={total}
                            onChange={(page, pageSize) => {
                                this.setState({ page }, () => {
                                    this.getList();
                                })
                            }} />
                    </>:<div className='none'>感谢您的关注，暂时没有最新职位</div>
                }
            </div>
        </Fragment>
    }
}