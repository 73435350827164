import { Modal } from 'antd';
import React, { Fragment } from 'react';
import './index.scss'
import Jquey from 'jquery'; 
import closeImg from '../../images/close.png';

export default function playVideo(videoUrl){
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const modal = Modal.info({
        className:"video-modal",
        keyboard:true,
        content:<Fragment>
            <video id='video' controlsList="nodownload"  autoPlay={true} controls={true} src={videoUrl}></video>
            <img className='close-img cp' alt='' src={closeImg} onClick={()=>{
                  window.scrollTo({
                    top: scrollTop,
                    behavior: "instant"
                })
                modal.destroy();
            }} />
        </Fragment>,
    })
    nodownloadFunc();
    
    function nodownloadFunc(){
        setTimeout(()=>{
            const myVideo = Jquey('#video')
            if(!!myVideo)
            myVideo.bind('contextmenu',function() { return false; });
            else nodownloadFunc();
        },1000)
    }
}  