const US = {
    cn: "cn",
    // header

    index: 'Home',
    parkrover: "Smart ParkRover",
    industryApply: 'Industry Application',
    news: 'Media',
    aboutus: 'About Us',

    //footer
    contact: 'TEL',// 联系方式 字段
    contacts: 'Mr Wang',// 联系人 实体
    email: 'Email',// 邮箱字段
    copyright: 'Copyright',
    copyrightDetail: 'Sichuan Golden Ridge Intelligence Science & Technology Co., Ltd.',
    ICP: 'Record number',
    ICPDetail: '蜀ICP备18023667号',
    consultation_southwest: 'China：',
    consultation_southwest_consultant1: 'Mr Luo 135-4796-7349',
    consultation_southwest_consultant2: 'Mr Wang 180-4030-5301',
    consultation_east: 'East China：',
    consultation_east_consultant1: 'Mr Fang 137-0185-3861',
    consultation_east_consultant2: 'Mr Liu 186-5598-4958',

    //indexpage
    boxGoldridgeTitle: 'Autonomous Driving Smart ParkRover',// parkrover页 首屏 标题
    boxGoldridgeSubTitle: 'The first 100 sets of premium edition are on sale in limited time',// 同上 副标题
    boxAutodriveTitle: 'Leader In Low-Speed Autonomous Driving Technology',// Autodrive 首屏 标题
    boxAutodriveSubTitle1: 'Fully guarantee driving and riding safety',
    boxAutodriveSubTitle: 'High precision positioning and navigation丨Accurate identification of obstacles 丨 360 ° real time monitoring perception  丨 Active safety decision mechanism 丨 Setting of electronic fence',// 同上 副标题
    boxIntellChassisTitle: 'Precision Chassis Wire Control Technology Developed',
    boxIntellChassisSubTitle: `"Functional body and chassis" separate design, modular packaging 丨 Independent suspension system, flexible steering control`,
    boxIndustryApply: 'Industry Application',
    boxIndustryApplicationTitle1: 'Smart ParkRover',
    boxIndustryApplicationTitle2: 'Smart Inspection Vehicle',
    boxIndustryApplicationTitle3: 'Smart Wheerchair II',
    boxIndustryApplicationTitle4: 'Smart Wheerchair I',
    buyNow: 'Buy Now',
    //indexpage sideBAr
    sideGoldenridge: 'GOLDENRIDGE',
    sideAutoDrive: 'Autonomous Driving',
    sideIntellChassis: 'Intelligent Chassis',
    sideIndustryApply: 'Industry Application',
    // parkrover section 1
    parkroverSectionHeaderTitle: 'Autonomous Driving Smart ParkRover',
    parkroverSectionHeaderText: 'Premium edition are on sale in limited time',
    // parkrover section 2
    productDetailText1: 'Dazizai Smart PorkRover,is independently designed and developed by Sichuan Golden Ridge Intelligence Science & Technology Co., Ltd. and released at Baidu Create AI Developer C onference 2019. It can be widely used in industrial / technological parks, tourist attractions, city parks, resorts and other scenes.',
    productDetailText2: 'Relying on the low-speed automatic driving technology and robot chassis control technology of Golden Ridge based on indoor and outdoor integration, the intelligent roaming car is a new entertainment carrier intelligent travel product integrating walking, technology, information and entertainment.',
    // parkrover section 3
    carSize: 'Body Size',
    chassisHeight: 'Chassis Height',
    trafficability: 'Trafficability',
    workingTemperature: 'Temperature',

    // parkrover section 4
    priceField: 'Price',// 售价字段
    priceDetail: '¥300,000/Unit',// 售价数字
    pDHeaderLeft: 'Deployment Costs ',
    pDHeaderLeftPrice: '¥60,000',
    pDHeaderRight: 'Annual Service Fee ',
    pDHeaderRightPrice: '¥50,000',
    pDItem1Title1: 'GNSS RTK System',
    pDItem1Title2: 'Charging Cabinet',
    pDItem1Title3: 'Survey, Design and Construction',
    pDItem1Title4: 'Tourist Guide Information Input',
    pDItem1Title5: 'Transportation Cost',
    pDItem1Title6: 'Technology Service Fee',
    pDItem1Title7: 'Special Insurance',
    pDItem1Title8: 'Warranty Extension Service',
    pDItem1Price1: '¥30,000/Unit',
    pDItem1Price2: '¥5,000/Unit',
    pDItem1Price3: '¥15,000/Unit',
    pDItem1Price4: '¥10,000/Unit',
    pDItem1Price5: '¥12/Km',
    pDItem1Price6: '¥30,000/ Unit/Y',
    pDItem1Price7: '¥5,000/ Unit/Y',
    pDItem1Price8: '¥15,000/ Unit/Y',

    pDHeaderDown: 'Package Offer',
    pDHeaderDownSub: 'Time Limited Preference',
    pdItemdownLeftTitle: 'Basic Package',
    pdItemdownLeftprice: '¥50,000',
    pdItemdownLeftSubTitle: 'Including all deployment costs',
    pdItemdownRightTitle: 'Supervalve Package',
    pdItemdownRightprice: '¥90,000',
    pdItemdownRightSubTitle: 'Including all deployment costs and annual service costs',

    // qualification
    qualTitle: 'Ingenious development is destined to be extraordinary',
    qualText: 'It has passed the vehicle qualification certification of the testing center of China Automotive Engineering Research Institute Co., Ltd. and international CE certification, and won the German UX Design Award.',
    qualSubTitle1: 'German UX Design Award',
    qualSubTitle2: 'Vehicle Qualification',
    qualSubTitle3: 'CE Certification',
    // scene 
    sceneText: 'Diversified Application Scenarios',

    //sites citys
    cityChengdu: 'Chengdu',
    cityBeijing: 'Beijing',
    cityGuangzhou: 'Canton',
    cityVienna: 'Vienna',
    // sites events
    event1: 'Xinglong Lake of Chengdu',
    event2: 'Xinglong Lake of Chengdu',
    event3: 'North Lake Park of Chengdu',
    event4: 'North Lake Park of Chengdu',
    event5: 'Jiangtan Park of Chengdu',
    event6: 'Jiangtan Park of Chengdu',
    event7: 'Baidu Technology Park of Beijing',
    event8: 'Baidu Create AI Developer Conference  2018 of Beijing',
    event9: 'Baidu Create AI Developer Conference  2019 of Beijing',
    event10: 'Baidu Create AI Developer Conference  2019 of Beijing',
    event11: 'Intelligent Connected Vehicle Driving Competition 2019 of Canton',
    event12: 'Intelligent Connected Vehicle Driving Competition 2019 of Canton',
    event13: 'Intelligent Connected Vehicle Driving Competition 2019 of Canton',
    event14: 'The Future of world Transport Conference of Vienna',
    event15: 'The Future of world Transport Conference of Vienna',

    //about us 
    aboutusBannerTitle: "Introduction",
    aboutusBannerDesc1: 'Golden Ridge which originated from Golden Ridge Robotic and Techno Creatives has rich experience in providing human-computer interaction experience and concept car design for famous car companies such as Volvo, Audi, Mercedes Benz and Saab for a long time. It has won more than ten international industrial design top awards such as Red Dot Award and IF Design Award.',
    aboutusBannerDesc2: 'Golden Ridge gathers talents from first-class enterprises and universities in China, Europe and the United States. It has internationally leading low-speed automatic driving technology and robot chassis control technology based on indoor and outdoor integration. It is a global leader in low-speed automatic driving technology solutions and services.',
    aboutusBannerDesc3: 'Golden Ridge has reached a deep strategic cooperation with Baidu, and its core achievements are widely used in many industries such as culture and tourism, rehabilitation, logistics, etc., and is committed to providing users with high-quality intelligent travel services.',
    // about us  milestone
    mileStoneTitle: 'Milestone  ',

    mileStoneDesc1: 'Golden Ridge Robotic AB was set up in Gothenburg, Sweden',
    mileStoneDesc2: 'Prototype was exhibited in Shenzhen Entrepreneurship And Innovation Week',
    mileStoneDesc3: 'Golden Ridge settled in Chengdu and Suzhou, China',
    mileStoneDesc4: 'Smart Wheerchair I was on display at Beijing International Health AIDS Expo.',
    mileStoneDesc5: 'Smart Wheerchair II was on display at Suzhou Artificial Intelligence Expo.',
    mileStoneDesc6: 'Smart Wheerchair released at Baidu Create AI Developer Conference, Baidu World Conference and CES.',
    mileStoneDesc7: 'Smart ParkRover released at Baidu Create AI Developer Conference.',
    mileStoneDesc8: 'Smart ParkRover had put into trial run in China Conservation and Research Center for the Giant Panda,Xinglong Lake Park of Chengdu and North Lake Park of Chengdu.',
    mileStoneDesc9: 'Smart ParkRover has showed at The Future of Transport World Conference in Vienna.',
    mileStoneDesc10: 'Exclusive partner of The 23th Annual Chengdu International Auto Show.',
    mileStoneDesc11: '2020 Baidu Apollo Ecological Conference.',
    mileStoneDesc12: '2020 Gulf Information Technology Exhibition.',

    // about us honor
    aboutusHonorTilte: 'Enterprise Qualification and Honor',
    aboutusHonorSubtitle1: 'National high tech enterprise ',
    aboutusHonorSubtitle2: 'UX design award',
    aboutusHonorSubtitle3: 'Vehicle qualification',
    aboutusHonorSubtitle4: 'CE Certification',

    //about us join us
    aboutusJoinusTitle: 'Join Us',
    aboutusJoinusSubtitle: "Sunny youth, In the prime of life; Do not forget the original heart, Forge ahead",
    aboutusJoinusBtn: 'View Positions Now',

    //about us contact us
    aboutusContactusTitle: 'Contact Us',

    aboutusContactusItemContacts: 'Email：service@goldenridge.ltd',
    aboutusContactusItemPhone: 'Website：www.goldenridge.cn',
    aboutusContactusItem1Title: 'Sichuan Golden Ridge Intelligence Science & Technology Co., Ltd.',
    aboutusContactusItem1Address: 'Address：3 / F, Building B2, Area D, Tianfu Jingrong Center, Chengdu Science City, Tianfu New District, Chengdu, China (Sichuan)  Pilot Free Trade Zone',

    aboutusContactusItem2Title: 'Suzhou Golden Ridge Intelligent Technology Co., Ltd.',
    aboutusContactusItem2Address: 'Address：6 / F, Building A, Dongfang Chuangzhi Garden, 18 Jinfang Road, Suzhou Industrial Park, Suzhou, Jiangsu Province',

    aboutusContactusItem3Title: ' Golden Ridge Robotic AB',
    aboutusContactusItem3Address: 'Address：Smörgatan 16, Gothenburg, Sweden ',

    aboutusSideTitle1: 'Introduction',
    aboutusSideTitle2: 'Milestone',
    aboutusSideTitle3: 'Qualification and Honor',
    aboutusSideTitle4: 'Join Us',
    aboutusSideTitle5: 'Contact Us ',

    // appliction 
    applictionTitle1: "Smart Inspection Vehicle",
    applictionTitle2: "Smart Wheerchair II",
    applictionTitle3: "Smart Wheerchair I",
    towatchVideo: 'View Video',

    applictionSideTitle1: 'Smart Inspection Vehicle',
    applictionSideTitle2: 'Smart Wheerchair II',
    applictionSideTitle3: 'Smart Wheerchair I',

    // tips
    tips: 'Tips：This video contains customized development content, and the actual products are subject to the agreed delivery terms',
}
export default US;