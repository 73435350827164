import axios from 'axios';
import { message } from 'antd';
import { getToken } from './authority';
import { createBrowserHistory } from 'history';
let HOST = window.location.host;

HOST = HOST.includes('localhost')?"https://www-dev.goldenridge.cn":"https://"+HOST;// 本地调试 用dev 环境
// HOST = 'https://www.goldenridge.cn';

// HOST = 'http://192.168.0.116:8080';
axios.defaults.baseURL = HOST;
function myAxios({ method = 'post', url, data = {}, success = () => { }, fail = () => { }, complete = () => { },headers = {} }) {
  axios({
    method: method,
    dataType: 'json',
    url: url,
    data,
    headers,
  }).then((res) => {
    if(res.data.code === 0){
      if (typeof (success) === 'function') {
        success(res.data); 
      }
      return;
    }else{
      message.warn(res.data.msg);
    }
  }).catch((err) => {
    message.info('服务器错误!');
    if (typeof (fail) === 'function') {
      fail();
    }
  }).finally(()=>{
    if (typeof (complete) === 'function') {
      complete();
    }
  });
}
function myAxiosWithJwt({ method = 'post', url, data = {}, success = () => { }, fail = () => { }, complete = () => { } }) {
  axios({
    method: method,
    dataType: 'json',
    url: url,
    data: data,
    headers: {
      Authorization: getToken(),
    },
  }).then((res) => {
    if(res.data.code === 0){
      if (typeof (success) === 'function') {
        success(res.data); 
      }
      return;
    }else{
      message.warn(res.data.msg);
      setTimeout(()=>{
        createBrowserHistory({
          forceRefresh: true,// 跳转强制刷新
        }).push('/login');
      },1500)
    }
    if(res.data.code === 1000039){
      setTimeout(()=>{
        createBrowserHistory({
          forceRefresh: true,// 跳转强制刷新
        }).push('/login');
      },1500)
      return ;
    }
  }).catch((err) => {
    message.info('服务器错误!');
    if (typeof (fail) === 'function') {
      fail();
    }
  }).finally(()=>{
    if (typeof (complete) === 'function') {
      complete();
    }
  });
}

export { HOST, myAxios, myAxiosWithJwt }

