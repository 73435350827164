import React, { Component } from 'react';
import { FormattedMessage as FM } from 'react-intl'; /* react-intl imports */
import './index.scss';

import swiperImg1 from '../../images/parkrover/swiper1.png';
import scene1 from '../../images/parkrover/scene.png';
import swiperImg3 from '../../images/parkrover/swiper3.jpg';
import swiperImg4 from '../../images/parkrover/swiper4.jpg';
import swiperImg5 from '../../images/parkrover/swiper5.jpg';
const sceneList = [{
    image: scene1,
    key: '场景1'
}, {
    image: swiperImg1,
    key: '场景2'
}, {
    image: swiperImg3,
    key: '场景3'
}, {
    image: swiperImg4,
    key: '场景4'
}, {
    image: swiperImg5,
    key: '场景5'
}]
export default class Scene extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sceneTab: 0,// 应用场景 当前进入的图片
            sceneFormerTab: -1,// 应用场景 当前退出的图片
            type: 'left',// 下次动画的方向  left  right 
        }
        this.isMoving = false;// 标记没有正在动画
        this.setDate = (state) => {
            if (!this.isMoving)// 如果动画执行完成
                this.setState(state, () => {// 每次设置超过以后都要执行动画  需要一秒  所以一秒以内 不能再 设置动画
                    this.isMoving = true;
                    if (!this.isMovingInterval) {
                        this.isMovingInterval = setTimeout(() => {
                            this.isMoving = false;
                            this.isMovingInterval = null;
                        }, 1200)
                    }
                })
        }
    }
    componentDidMount() {
        this.listenerNode = document.getElementById('scene');
        //滑动处理
        this.listenerNode.addEventListener('touchstart', this.touchStart);
        this.listenerNode.addEventListener('touchend', this.touchEnd);
    }
    touchStart = (e) => {
        e.preventDefault();
        this.startX = e.touches[0].pageX;
    }
    touchEnd = (e) => {
        e.preventDefault();
        this.moveEndX = e.changedTouches[0].pageX;
        this.X = this.moveEndX - this.startX;
        if (this.X < -30) {
            this.courseFunc.call(this, 'left')
        }
        else if (this.X > 30) {
            this.courseFunc.call(this, 'right')
        }

    }
    componentWillUnmount() {
        this.listenerNode.removeEventListener('touchstart', this.touchStart)
        this.listenerNode.removeEventListener('touchend', this.touchEnd)
    }
    courseFunc(type) {
        const { sceneTab } = this.state;
        if (sceneTab === 0 && type === 'right') {
            this.setDate({
                sceneFormerTab: sceneTab,
                sceneTab: sceneList.length - 1,
                type,
            })
        } else if (sceneTab === sceneList.length - 1 && type === 'left') {
            this.setDate({
                sceneFormerTab: sceneTab,
                sceneTab: 0,
                type,
            })
        } else {
            this.setDate({
                sceneFormerTab: sceneTab,
                type,
                sceneTab: type === 'left' ? sceneTab + 1 : sceneTab - 1,
            })
        }
    }
    render() {
        const { sceneTab, sceneFormerTab, type } = this.state;
        return <div className='scene' >
            <div id='scene'>
                {sceneList.map((item, index) => <img
                    className={`${index === sceneFormerTab ? `imgs-${type}-hide` : (index === sceneTab ? `imgs-${type}-show` : '')}`}
                    alt='' src={item.image} key={item.key} />)}
            </div>
            <div className='course-dot'>{sceneList.map((item, index) => <span className={sceneTab === index ? 'active' : ''} key={item.key}></span>)}</div>
            <span className='left-arrow  cp' onClick={this.courseFunc.bind(this, 'right')}></span>
            <span className='right-arrow cp' onClick={this.courseFunc.bind(this, 'left')}></span>
            <div className='cover'><span><FM id='sceneText' /></span></div>
        </div>
    }
}